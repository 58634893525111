<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <dividend-modal
        heading="Withdraw Dividends"
        body="How much of your dividend balance would you like to withdraw?"
        :value="claimableAmount"
        :show="modals.showWithdrawDividendModal"
        @close="closeModal"
        @update="updatePercentToClaim"
    >
        <primary-button @click="withdrawRewards">
          Withdraw
          <font-awesome-icon icon="circle-notch" spin v-if="loading" class="ml-3" />
        </primary-button>
    </dividend-modal>
</template>

<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import useModals from '../../../stores/useModals'
import DividendModal from './DividendModal.vue'
import Moralis from 'moralis';
import { formatNumber } from '@/utils.js';

export default {
  components: {
    DividendModal,
    PrimaryButton
  },
  setup() {
    const modals = useModals();
    return {
      modals
    }
  },
  data() {
    return {
      percentToClaim: 0,
      loading: false,
    }
  },
  computed: {
    claimableAmount() {
      const balance = this.$store.state.claimableEthAmount;
      const amount = formatNumber(Moralis.Units.FromWei(balance));
      if(this.percentToClaim === 100) return amount;
      const percent =  amount / 100 * this.percentToClaim;
      return percent.toFixed(4);
    }
  },
  methods:{
    updatePercentToClaim(val){
      this.percentToClaim = val;
    },
    async withdrawRewards() {
      if(this.loading || this.percentToClaim == 0 || this.claimableAmount == 0)
        return;
      try {
        this.loading = true;
        await this.$store.state.rewardContract.methods.withdraw(this.percentToClaim).send({
          from: this.$store.state.connected_address
        });

        await this.$store.dispatch('reloadClaimableEthAmount');
        await this.$store.dispatch('reloadClaimedEthAmount');
        
        this.closeModal();
      } catch(e) {
        console.log(e);
      }
      this.loading = false;
    },
    closeModal() {
      this.modals.toggleWithdrawDividendModal();
    }
  }
}
</script>