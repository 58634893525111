<template>
  <div class="mt-8 mx-auto">
                  <h1
            class="mt-12 text-dark text-3xl lg:text-5xl font-medium text-center capitalize"
          >
            Rewards Payout
          </h1>
    <div class="flex items-center lg:hidden">
      <img src="/img/dog.png" class="w-48 mx-auto" />
    </div>
    <div class="items-center hidden lg:flex">
      <!-- <img src="/img/dog.png" /> -->
      <div class="pr-8 mx-auto">
        <Vue3Lottie :animationData="AnimationJSON" />
      </div>
    </div>
    <!-- Hero text content -->
    <div class="flex flex-col items-center lg:items-start col-span-2 mx-auto">
      <div class="mx-auto">
        <span
          class="inline-block text-xl px-4 py-2 font-medium transform text-white bg-secondary-light rounded-full shadow-md -rotate-3 -ml-4"
        >
          Comming Soon
        </span>
      </div>
         <h1
                class="mt-4 text-gray-600 text-2xl mx-auto  font-medium  max-w-3xl text-center capitalize"
              >
               The rewards platform is still in development and should be released soon
              </h1>
    </div>
  </div>
</template>

<script>
const AnimationJSON = require("@/ShibvinciCharacter.json");
export default {
  name: "CommingSoon",
    setup() {
    return { AnimationJSON };
  },
};
</script>

<style lang="scss" scoped></style>