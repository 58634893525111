<template>
  <li class="flex items-center text-dark">
    <img v-if="complete" class="flex-shrink-0 mr-3 w-7 h-7" src="images/checkmark.svg" />
    <img v-else class="flex-shrink-0 mr-3 w-7 h-7" src="images/checkmark-grey.svg" />
    <span><slot /></span>
  </li>
</template>

<script>
export default {
  name: "RoadmapListItem",
  props:{
    complete: Boolean
  }
};
</script>

<style scoped></style>
