<template>
    <base-button class="border-gray-300 text-primary bg-white hover:bg-gray-50">
        <slot />
    </base-button>
</template>

<script>
  import BaseButton from './BaseButton.vue'
    export default {
        name:"DefaultButton",
                components: { BaseButton },
    }
</script>

<style scoped>

</style>