<template>
 <div class=" pt-4">
    <div class="max-w-7xl mx-auto pb-20 md:pb-32 pt-12 md:pt-20">
    <h1 class="font-bold text-5xl md:text-7xl mb-8 text-white text-center">
      <span class="relative block">
        <span class="relative">
          <img
            class="absolute inset-0 transform translate-y-12 sm:translate-y-12 md:translate-y-14"
            src="images/underline-simple-orange.svg"
          />
          <span class="relative">Buy Now</span>
        </span>
      </span>
    </h1>
    <div class="flex flex-col items-center justify-center  sm:flex-row">
      <primary-button xl class="mr-6" @click="buyOnUniswap"
        ><strong> BUY NOW ON UNISWAP <font-awesome-icon icon="long-arrow-right" class="ml-2" /></strong></primary-button
      >
      <secondary-button xl class="mt-4 md:mt-0" @click="buyOnTelegram"
        ><strong><font-awesome-icon :icon="['fab', 'telegram']" class="mr-2" /> JOIN TELEGRAM</strong></secondary-button
      >
    </div>
  </div>
 </div>
</template>

<script>
import { telegram, uniswap } from "@/links";

export default {
  name: "BuyNowAlt",
  methods: {
    buyOnTelegram() {
      window.open(telegram, "_blank").focus();
    },
    buyOnUniswap() {
      window.open(uniswap, "_blank").focus();
    },
  },
};
</script>

<style lang="scss" scoped></style>
