import {
  createStore
} from 'vuex'
import Web3 from "web3";
import Moralis from 'moralis';
import find from 'lodash/find'
import reduce from 'lodash/reduce';
import {
  formatStringWithCommas
} from './utils';

export const DEFAULT_TRANSACTION = {
  status: 0, // 1 - started | 2 - got hash | 3 - got receipt
  ref: "",
  method: "",
  call: false,
  send: false,
  arg1: false,
  arg2: false,
  error: false
};

// Create a new store instance.
const store = createStore({
  modules: {
  },
  state() {
    return {
      web3: {},
      ready: false,
      connected_address: "",
      connected_network: "",
      tokens: "0",
      eth_balance: 0,
      
      rewardContract: {},
      shivContract: {},
      shivBalance: 0,
      stakedShivAmount: 0,
      claimableEthAmount: 0,
      claimedEthAmount: 0,
      reinvestedEthAmount: 0,

      newTransaction: Object.assign({}, DEFAULT_TRANSACTION),
      newTransactionConfirmationModal: {
        open: false,
        hash: ""
      },
    }
  },
  getters: {
    connected(state){
      return (
        parseInt(process.env.VUE_APP_CHAIN) === state.connected_network &&
        state.connected_address
      );
    }
  },
  mutations: {
    updateAddress(state, address) {
      state.connected_address = address;
    },
    updateWeb3(state, web3) {
      state.web3 = web3
    },
    setReady(state) {
      setTimeout(() => {
        // Makes the loader smoother
        state.ready = true;
      }, 3000);
    },
    updateNetwork(state, network) {
      state.connected_network = network;
    },
    updateBalance(state, balance) {
      state.eth_balance = balance;
    },
   
    updateTransaction(state, newData) {
      state.newTransaction = {
        ...state.newTransaction,
        ...newData
      }
    },
    updateConfirmationModal(state, data){
      state.newTransactionConfirmationModal = data;
    },
    updateRewardContract(state, data) {
      state.rewardContract = data;
    },
    updateShivContract(state, data) {
      state.shivContract = data;
    },
    updateStakedAmount(state, newValue) {
      state.stakedAmount = newValue;
    },
    updateClaimableEthAmount(state, newValue) {
      state.claimableEthAmount = newValue;
    },
    updateClaimedEthAmount(state, newValue) {
      state.claimedEthAmount = newValue;
    },
    updateReinvestedEthAmount(state, newValue) {
      state.reinvestedEthAmount = newValue;
    },
    updateShivBalance(state, newValue) {
      state.shivBalance = newValue;
    }
  },
  actions: {
    async loadWeb3({commit, dispatch}) {
      // logout when they change
      const web3 = new Web3(window.ethereum)
      const network = await web3.eth.getChainId();
      const accounts = await web3.eth.getAccounts();
      
      const rewardContract = await new web3.eth.Contract(require("./abis/ShibVinciRewards.json"), process.env.VUE_APP_REWARD_ADDRESS);
      const shivContract = await new web3.eth.Contract(require("./abis/ShibVinci.json"), process.env.VUE_APP_SHIV_ADDRESS);

      if (network === parseInt(process.env.VUE_APP_CHAIN) && accounts[0]) {
        const nativeBalance = await web3.eth.getBalance(accounts[0])
        commit('updateBalance', web3.utils.fromWei(nativeBalance));
      }

      commit('updateWeb3', web3);
      commit('updateNetwork', network);
      commit('updateAddress', accounts[0]);
      commit('updateRewardContract', rewardContract);
      commit('updateShivContract', shivContract);
      commit('setReady');

      await dispatch('reloadStakedAmount');
      await dispatch('reloadClaimableEthAmount');
      await dispatch('reloadClaimedEthAmount');
      await dispatch('reloadReinvestedEthAmount');
      await dispatch('reloadShivBalance');
    },

    async reloadStakedAmount({
      state,
      commit
    }) {
      const stakedAmount = await state.rewardContract.methods.getStakedAmount(state.connected_address).call();
      commit('updateStakedAmount', stakedAmount);
    },

    async reloadClaimableEthAmount({
      state,
      commit
    }) {
      let BN = Web3.utils.BN;
      let amount1 = await state.rewardContract.methods.getUnpaidDividend(state.connected_address).call();
      let amount2 = await state.rewardContract.methods.getClaimableDividend(state.connected_address).call();
      let amount = new BN(amount1).add(new BN(amount2)).toString();
      commit('updateClaimableEthAmount', amount);
    },

    async reloadClaimedEthAmount({
      state,
      commit
    }) {
      const claimedEthAmount = await state.rewardContract.methods.getTotalClaimedDividend(state.connected_address).call();
      commit('updateClaimedEthAmount', claimedEthAmount);
    },

    async reloadReinvestedEthAmount({
      state,
      commit
    }) {
      const reinvestedEthAmount = await state.rewardContract.methods.getTotalReinvestedDividend(state.connected_address).call();
      commit('updateReinvestedEthAmount', reinvestedEthAmount);
    },

    async reloadShivBalance({
      state,
      commit
    }) {
      const shivBalance = await state.shivContract.methods.balanceOf(state.connected_address).call();
      commit('updateShivBalance', shivBalance);
    },

    async requestNetworkChange({
      state
    }) {
      await state.web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: process.env.VUE_APP_NETWORK
        }]
      })
    },

    async requestConnectAccount({
      state
    }) {
      await state.web3.currentProvider.request({
        method: "eth_requestAccounts",
      })
    }
  }
});
export default store;