<template>
  <secondary-button v-if="! $store.state.ready" disabled>
    <font-awesome-icon icon="spinner-third" spin
  /></secondary-button>
  <secondary-button
    v-else-if="!$store.state.connected_address"
    @click="$store.dispatch('requestConnectAccount')"
  >
    Connect Wallet</secondary-button
  >
  <secondary-button
    v-else-if="$store.state.connected_network !== chainId"
    @click="$store.dispatch('requestNetworkChange')"
  >
    Switch Network</secondary-button
  >
  <slot v-else />
</template>

<script>

export default {
  name: "WalletConnect",
  setup() {
    return {
      chainId: parseInt(process.env.VUE_APP_CHAIN),
    };
  },
};
</script>

<style lang="scss" scoped></style>
