<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed inset-0 overflow-hidden z-50"
        @close="close"
      >
        <div class="absolute inset-0 overflow-hidden">
          <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="relative w-screen max-w-xs">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div
                    class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                  >
                    <button
                      type="button"
                      class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      @click="close"
                    >
                      <span class="sr-only">Close panel</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div
                  class="h-full flex flex-col py-6 bg-dark shadow-xl overflow-y-scroll"
                >
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <nav class="space-y-1" aria-label="Sidebar">
                      <a
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        @click="close"
                        class="group flex items-center px-3 py-2 text-xl font-medium rounded-md text-white hover:bg-gray-50 hover:text-gray-900"
                        :aria-current="item.current ? 'page' : undefined"
                      >
                        <span class="truncate">
                          {{ item.name }}
                        </span>
                      </a>
                    </nav>
                    <primary-button  class="mt-4" @click="openModal">
                      <font-awesome-icon icon="rocket-launch" class="mr-2" />
                      Launch Dapp
                    </primary-button>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import {
  telegram,
  uniswap,
  whitepaper,
  chart,
  twitter,
  etherscan,
} from "@/links";
const navigation = [
  { name: "Chart", href: chart },
  { name: "Telegram", href: telegram },
  { name: "Trade", href: uniswap },
  { name: "Whitepaper", href: whitepaper },
  { name: "About", href: "#about" },
  { name: "Treasury", href: "#treasury" },
  { name: "Tokenomics", href: "#tokenomics" },
  { name: "How To Buy", href: "#howtobuy" },
  { name: "Roadmap", href: "#roadmap" },
];
export default {
  name: "MobileMenu",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    open: Boolean,
  },
  setup() {
    return {
      telegram,
      uniswap,
      whitepaper,
      chart,
      twitter,
      etherscan,
      navigation,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
       openModal(){
               this.$emit("close");
           this.$router.push({path:'/dapp'})
      },
  },
};
</script>
