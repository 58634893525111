<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <token-modal
        heading="Stake SHIVs"
        body="Be aware of this - Fee is applied when staking"
        :show="modals.showStakeModal"
        @close="closeModal"
        @update="updateTokenBalance"
    >
        <primary-button @click="stakeTokens">
          Stake
          <font-awesome-icon icon="circle-notch" spin v-if="loading" class="ml-3" />
        </primary-button>
    </token-modal>
</template>

<script>
import Moralis from 'moralis';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import useModals from '../../../stores/useModals'
import TokenModal from './TokenModal.vue'

export default {
  components: {
    TokenModal,
    PrimaryButton
  },
  setup() {
    const modals = useModals();
    return {
      modals
    }
  },
  data() {
    return {
      tokenAmount: 0,
      rewardContract: false,
      loading: false
    }
  },
  async mounted() {
    this.rewardContract = await new this.$store.state.web3.eth.Contract(require("../../../abis/ShibVinciRewards.json"), process.env.VUE_APP_REWARD_ADDRESS);
  },
  methods:{
      updateTokenBalance(val){
          this.tokenAmount = val;
      },
      async stakeTokens() {
        if(this.loading)
          return;
          
        try {
          this.loading = true;
          const amount = Moralis.Units.Token(this.tokenAmount, "9")
          await this.rewardContract.methods.stake(amount).send({
            from: this.$store.state.connected_address
          });

          await this.$store.dispatch('reloadStakedAmount');
          this.closeModal();
        }catch(e) {
          console.log(e);
        }
        this.loading = false;
      },
      closeModal() {
        this.modals.toggleStakeModal();
      }
  }
}
</script>