<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white rounded-xl shadow-sm">
    <h2 class="sr-only">Our perks</h2>
    <div
      class="max-w-7xl mx-auto divide-y divide-gray-200 lg:py-8 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x"
    >
      <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
        <div
          class="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8 w-80"
        >
          <div class="ml-4 flex-auto flex flex-col-reverse">
            <h3 class="font-bold text-2xl text-primary">
              <inline-loader :loading="nfts.fetchingData">{{
                nfts.nfts.length
              }}</inline-loader>
            </h3>
            <p class="text-base text-gray-500">Total Nfts</p>
          </div>
        </div>
      </div>
      <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
        <div
          class="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8"
        >
          <div class="ml-4 flex-auto flex flex-col-reverse">
            <h3 class="font-bold text-2xl text-primary">
              <inline-loader :loading="nfts.fetchingData">{{
                nfts.uniqueProjects.length
              }}</inline-loader>
            </h3>
            <p class="text-base text-gray-500">Total Collections</p>
          </div>
        </div>
      </div>
      <div class="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
        <div
          class="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8"
        >
          <div class="ml-4 flex-auto flex flex-col-reverse">
            <h3 class="font-bold text-2xl text-primary">
              <inline-loader :loading="nfts.fetchingData">{{
                nfts.estFloorValueUsd
              }}</inline-loader>
            </h3>
            <p class="text-base text-gray-500">Estimated Floor Value</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineLoader from "@/components/InlineLoader";

export default {
  name: "GalleryStats",
  props: {
    nfts: {},
  },
  components: {
    InlineLoader,
  },
  computed: {
    perks() {
      return [
        {
          name: "",
          value: this.nfts.nfts.length,
        },
        {
          name: "",
          value: this.nfts.uniqueProjects.length,
        },
        {
          name: "",
          value: this.nfts.estFloorValueUsd,
        },
      ];
    },
  },
};
</script>
