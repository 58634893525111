<template>
  <div class="flex justify-center ">
    <div>
      <div class="grid  grid-cols-1 lg:grid-cols-2">
        <div class="pt-4 md:pt-12">
            <h1
            class="mt-12 text-dark text-3xl lg:text-5xl font-medium lg:text-left text-center capitalize"
          >
            Shibvinci Rewards 
          </h1>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
            50% of Treasury profits from NFT Sales are sent to the rewards Wallet, where
            $SHIV token holders can withdraw their NFT profits in ETH, or
            reinvest in $SHIV.
          </p>
          <deposits />
        </div>
        <div class="mt-4 pb-12 sm:pb-16 xl:pl-12">
          <stake />
          <claim />
          <un-stake />
          <claimed />
          <!-- <reinvested /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Claim from './Claim.vue';
import Claimed from './Claimed.vue';
import Deposits from './Deposits.vue';
import Stake from './Stake.vue'
import Reinvested from './Reinvested.vue'
import UnStake  from './UnStake.vue';

const AnimationJSON = require("@/ShibvinciCharacter.json");
export default {
  components: { Deposits, Claim, Claimed, Reinvested, Stake, UnStake },
  name: "Dividends",
    setup() {
      return { AnimationJSON };
  },
};
</script>

<style lang="scss" scoped></style>
