<template>
    <base-button class="border-secondary text-white hover:bg-secondary-lighter hover:text-secondary  bg-secondary transition">
        <slot />
    </base-button>
</template>

<script>
    import BaseButton from './BaseButton.vue';

    export default {
        name:"SecondaryButton",
        components:{
            BaseButton
        }
    }
</script>

<style scoped>

</style>