<template>
    <div class="text-white font-medium text-lg items-center  flex font-display tracking-widest">
        <display-address :address="address" /> 
    </div>
</template>

<script>

import DisplayAddress from "./DisplayAddress.vue"

export default{
    name: "CurrentBalance",
    components: { DisplayAddress },
    computed:{
        address(){
            return (this.$store.state.connected_address);
        }
    },
}
</script>

<style scoped>

</style>