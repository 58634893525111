<template>
  <div class="relative c-wrapper">
    <div class="relative z-10 lg:py-32 py-12">
      <div class="max-w-7xl mx-auto">
        <section class="px-4 sm:px-6 lg:px-8">
          <!-- Hero container -->
          <div class="grid grid-cols-1 lg:grid-cols-3">
            <div class="flex items-center lg:hidden">
              <img src="/img/dog.png" class="w-48 mx-auto" />
            </div>
            <!-- Hero text content -->
            <div class="flex flex-col items-center lg:items-start col-span-2">
              <div>
                <span
                  class="inline-block text-xl px-4 py-2 font-medium transform text-white bg-secondary-light rounded-full shadow-md -rotate-3 -ml-4"
                >
                  Welcome to ShibVinci
                </span>
              </div>
              <h1
                class="mt-4 text-white text-3xl lg:text-5xl font-medium lg:text-left text-center capitalize"
              >
                ShibVinci is the first meme-based, governance token on the
                Ethereum network
              </h1>
              <p
                class="max-w-2xl mt-3 text-xl leading-loose text-center text-gray-50 font-medium text-shadow lg:text-left"
              >
                ShibVinci allows investors to increase the value of their $SHIV
                tokens by community-governed trading of NFTs using the ShibVinci
                DAO backed Treasury.
              </p>

              <!-- Hero buttons -->
              <div
                class="flex flex-col items-center mt-8 overflow-hidden sm:flex-row"
              >
                <telegram-button />
                <secondary-button xl class="mt-4 md:mt-0 ml-4 lg:ml-0" @click="playVideo"
                  ><PlayIcon class="h-6 w-6 mr-2" />Play Video</secondary-button
                >
              </div>
            </div>

            <div class="items-center hidden lg:flex">
              <!-- <img src="/img/dog.png" /> -->
              <div class="pr-8">
                <Vue3Lottie
                  :animationData="AnimationJSON"
             
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { telegram } from "@/links";
import TelegramButton from "../../../components/buttons/TelegramButton.vue";
import SecondaryButton from "../../../components/buttons/SecondaryButton.vue";
import { PlayIcon } from "@heroicons/vue/solid";
const AnimationJSON = require("@/ShibvinciCharacter.json");

export default {
  components: { TelegramButton, SecondaryButton, PlayIcon },
  name: "Hero",
  setup() {
    return { AnimationJSON };
  },
  methods: {
    goToTelegram() {
      window.open(telegram, "_blank").focus();
    },
    goToDapp() {
      this.$router.push({ path: "/dashboard" });
    },
    playVideo(){
      window.location.href ="#video";
    }
  },
};
</script>

<style scoped></style>
