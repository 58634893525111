import {
  defineStore
} from 'pinia'
import Moralis from 'moralis';
import Web3 from 'web3';
import useEthUsdPrice from './useEthUsdPrice';

export default defineStore('treasury', {
  state: () => {
    return {
      rawBalance: 0,
      fetchingData: false,
      gotData: false,
      error: false
    }
  },
  getters: {
    balance(state) {
      if (!state.rawBalance) return 0;
      return parseFloat(Moralis.Units.FromWei(state.rawBalance)).toFixed(2);
    },
    balanceUsd(state) {
      const math = require('mathjs')
      const ethUsdPrice = useEthUsdPrice();
      const value = math.multiply(Moralis.Units.FromWei(state.rawBalance), ethUsdPrice.value);
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol'
      }).format(value)
    }
  },
  actions: {
    async fetchData() {
      this.fetchingData = true;
      try {
        const web3 = new Web3(process.env.VUE_APP_SPEEDY_NODE);
        this.rawBalance = await web3.eth.getBalance(process.env.VUE_APP_TREASURY_WALLET);
        this.gotData = true;
        setTimeout(() => {
          this.fetchingData = false;
        }, 1000)
      } catch (err) {
        this.gotData = true;
        this.fetchingData = false;
        this.error = err.message;
      }
    },
  },
})