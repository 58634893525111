<template>
<div id="howtobuy" class="bg-white pt-12 sm:pt-20 lg:pt-32 lg:pb-44 sm:pb-24 pb-20 relative overflow-hidden">
  <img src="/images/dots-large-grid.png" class="dots1 absolute  -bottom-20 opacity-10 hidden lg:block" style="left: -350px"/>
  <img src="/images/dots-large-grid.png" class="dots2 absolute -top-20  opacity-10 hidden lg:block" style="right: -350px"/>
   <div class="max-w-7xl mx-auto relative z-10">
     <h1 class="font-bold text-4xl md:text-7xl  mb-8 text-secondary text-center">
          <span class="relative block">
            <span class="relative">
              <img
                class="absolute inset-0 transform translate-y-12 md:translate-y-14"
                src="images/underline-simple-orange.svg"
              />
              <span class="relative">How To Buy</span>
            </span>
          </span>
        </h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-4 ">
        <div v-for="block in blocks" :key="block.headline" class="bg-secondary text-center relative p-4 rounded-2xl">
            <img
            class="w-24 mx-auto border-1 border-white rounded-full bg-white p-1"
            :src="block.image"
          />
        
          <h3 class="font-display text-6xl pt-2 font-bold text-white font-written">{{block.headline}}</h3>
          <p class="text-white mb-6 text-base tracking-wide">
            {{block.para1}}
          </p>
          <p class="text-white mb-6 text-base tracking-wide">
            {{block.para2}}
          </p>
        </div>
      </div>
    </div>
</div>
</template>

<script>
const blocks = [
  {
    headline: "STEP 1",
    image: "/img/metamask-fox.png",
    para1:"Download the MetaMask Chrome Extension or mobile app.",
    para2:"Setup your account & make sure you’re connected to the ethereum network."
  },
    {
    headline: "STEP 2",
    image: "/img/buynow/2.png",
    para1:"Use Coinbase / another exchange to purchase ETH and send it over to your MetaMask Wallet Address.",
    para2:"Or if you already have ETH, simply send it to your MetaMask Wallet."
  },
    {
    headline: "STEP 3",
    image: "/img/buynow/3.png",
    para1:"Head over to the Uniswap Exchange and swap your ETH for $SHIV.",
    para2:"(if you already hold another token, you can swap that directly for $SHIV too)"
  },
    {
    headline: "STEP 4",
    image: "/img/buynow/4.png",
    para1:"Congratulations! You are now an Official ShibVinci Holder.",
    para2:"Join our Telegram to stay up to date with the teams announcements & progress."
  }
]
export default {
  name: "HowToBuy",
  setup(){
    return {blocks}
  }
};
</script>

<style lang="scss" scoped></style>
