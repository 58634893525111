<template>
  <div class="pt-8 ml-8  mb-12">
    <h1 class="font-bold text-3xl mb-8 text-secondary">
      <span class="relative block">
        <span class="relative">
          <img
            class="absolute inset-0 transform translate-y-10"
            src="/images/underline-simple-orange.svg"
          />
          <span class="relative">Buyback & Burns</span>
        </span>
      </span>
    </h1>
      <div class="flow-root">
    <ul role="list" class="-mb-8 pt-6">
      <li v-for="(transaction, indx) in transactions" :key="indx">
        <burn v-if="transaction.to_address==='0x000000000000000000000000000000000000dead'"  :transaction="transaction"/>
        <buyback v-else :transaction="transaction" />
      </li>
    </ul>
  </div>
    
  </div>
</template>

<script>
import Moralis from "moralis";
import Buyback from './buyback.vue';
import burn from './burn.vue'

export default {
  name: "buybacks",
  components:{
    Buyback, burn
  },
  data() {
    return {
      transactions: [],
      gotData: false,
      gettingData: true,
    };
  },
  async mounted() {
    const options = {
      address: "0x56C337AF90716638E587357Ec0e31A1Fda1454aA",
      order: "desc",
      from_block: "0",
    };
    const transactions = await Moralis.Web3API.account.getTokenTransfers(options);
    console.log(transactions)
    this.transactions = transactions.result;
  },
};
</script>

<style scoped></style>
