<template>
    <button :class="classList"><slot /></button>
</template>

<script>
    export default {
        name: "BaseButton",
        props:{
            xs: Boolean,
            sm: Boolean,
            md: Boolean,
            lg: Boolean,
            xl: Boolean,
            disabled: Boolean
        },
        computed:{
            classList(){
                const baseClasses = "inline-flex items-center border shadow-sm rounded focus:ring-2 focus:ring-offset-2 focus:outline-none rounded-full cursor-pointer ";
                return baseClasses + this.size + this.$attrs.class;
            },
            size(){
                if(this.xs) {
                    return  "px-2.5 py-1.5 text-xs font-medium"
                }
                if(this.xl){
                    return " px-8 py-4  text-lg  font-semibold tracking-wider"
                }
                if(this.lg){
                    return " px-5 py-3  text-base font-medium "
                }
        
                return "inline-flex items-center px-4 py-2  text-sm  shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            }
        }
    }
</script>

<style scoped>

</style>