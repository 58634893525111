<template>
  <div class="pt-12 wrapper 2xl:max-w-7xl xl:max-w-5xl" >
    <div class="px-4 sm:px-6 lg:px-8 flex justify-center">
      <div class="" >
                <h1
            class="mt-4 mb-4 text-dark text-3xl lg:text-5xl font-medium  text-center capitalize"
          >
            The ShibVinci Gallery
          </h1>

        <GalleryStats :nfts="nfts" />
      </div>
    </div>

    <div v-if="nfts.gotData" class="gallery-container relative grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 md:space-x-8 mt-12">
        <GalleryItem
            v-for="(nft, index) in nfts.nfts"
            :nft="nft"
            :key="index"
        />
    </div>
    <font-awesome-icon v-else icon="spinner-third" spin />
  </div>
</template>

<script>
import useNfts from "@stores/useNfts";
import GalleryItem from './GalleryItem.vue';
import GalleryStats from './GalleryStats.vue'

export default {
  components: { GalleryItem, GalleryStats },
  name: "Gallery",
  computed: {}, // 0xffa9B09AeBE228FBb9926b14E77065F10f9A9460
  setup() {
    const nfts = useNfts();

    if (!nfts.gotData && !nfts.fetchingData) {
      nfts.fetchData();
    }
    return { nfts };
  },
};

</script>

<style scoped>
.gallery-container {
    
    /* display: flex;
  flex-wrap: nowrap; */
  padding-bottom: 100px;
}
@media (max-width: 1280px) { 
  .wrapper {
    max-width: 100vw
  }
 }

</style>
