<template>
  <transition name="fade" appear mode="out-in">
    <div v-if="loading" class="loader-container justify-center flex items-center relative z-20 h-screen">
      <div class="max-w-lg">
        <img id="my-logo" src="http://shibvinci.com/img/logo-2.png"  class="blink"/>
      </div>
    </div>
    <div v-else>
      <slot  />
    </div>
  </transition>
</template>

<script>
import useEthUsdPrice from '@stores/useEthUsdPrice';

export default {
  name: "PageLoading",
  setup(){
      const EthUsdPrice = useEthUsdPrice();
      EthUsdPrice.fetchData();
      return {EthUsdPrice}
  },
  data() {
    return {
      loading: true
    }
  },
  computed:{
    ready(){
      return this.$store.state.ready;
    }
  },
  async mounted() {
    try{
      if(typeof window.ethereum!=='undefined'){
        window.ethereum.autoRefreshOnNetworkChange = false;
        this.$store.dispatch('loadWeb3');

        /* Case 2 - User switch account */
        window.ethereum.on('accountsChanged', async () => {
          this.$store.dispatch('loadWeb3');
        });
        /* Case 3 - User switch network */
        window.ethereum.on('chainChanged', async () => {
          this.$store.dispatch('loadWeb3');
        });
      }else {
        this.noMetaMask = true;
      }
    } catch (e) {
      console.error('Error, load Web3: ', e)
    }
  },
  watch:{
    ready(newVal){
      if(newVal){
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>