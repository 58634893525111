<template>
  <div style="background: #f5f5f5">
    <div
      class="bg-white overflow-hidden h-screen overflow-y-scroll"
      style="background: #f5f5f5"
    >
      <banner />
      <mobile-dapp-menu :open="open" @close="close" />
      <!-- <img
      src="/img/dapp-background.png"
      class="absolute top-0 bottom-0 left-0 right-0 z-10 w-screen h-screen"
    /> -->
     
      <page-loader>
        <withdraw-dividend-modal />
        <reinvest-dividend-modal />
        <stake-token-modal />
        <div class="z-20 relative">
          <div class="sidebar h-20 w-screen px-8 hidden lg:flex items-center">
            <img src="/img/logo-2.png" alt="Bright" class="w-32" />
            <sidebar />
          </div>
          <div
            class="px-12 mx-auto pl-4 pr-4 flex-grow h-screen"
            style="max-width: 1250px"
          >
            <div class="lg:hidden flex justify-between px-2 mb-4">
              <img src="/img/logo-2.png" alt="Bright" class="w-36" />
              <primary-button class="mr-4" @click="setOpen"
                ><MenuIcon class="h-5 w-5"
              /></primary-button>
            </div>
      
            <router-view v-slot="{ Component }">
              <transition name="scale" mode="out-in">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
        </div>
      </page-loader>
    </div>
  </div>
</template>

<script>
import sidebar from "../modules/dapp/sidebar/sidebar.vue";
import PageLoader from "../components/PageLoader.vue";
import PrimaryButton from "../components/buttons/PrimaryButton.vue";
import { MenuIcon } from "@heroicons/vue/solid";
import MobileDappMenu from "@/modules/dapp/MobileDappMenu";
import Banner from "@/modules/dapp/Banner.vue";
import ReinvestDividendModal from "../modules/dapp/dividends/ReinvestDividendModal.vue";
import WithdrawDividendModal from "../modules/dapp/dividends/WithdrawDividendModal.vue";
import StakeTokenModal from "../modules/dapp/dividends/StakeTokenModal.vue";

export default {
  name: "Dapp",
  components: {
    sidebar,
    PageLoader,
    MenuIcon,
    MobileDappMenu,
    PrimaryButton,
    Banner,
    WithdrawDividendModal,
    ReinvestDividendModal,
    StakeTokenModal
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {},
  methods: {
    close() {
      this.open = false;
    },
    setOpen() {
      this.open = true;
    },
  },
  watch: {
    $route() {
      this.close();
    },
  },
};
</script>

<style scoped>
.sidebar {
  background: linear-gradient(
    to top,
    rgba(0, 38, 73, 0.8),
    rgba(0, 38, 73, 0.95)
  );

  animation: blinker 0.07s ease-in-out;
}

@keyframes blinker {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
</style>
