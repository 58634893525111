<template>
  <li
    class="flex flex-shrink max-w-xs cursor-pointer text-gray-50 hover:text-white"
    @click="goToLink"
  >
    <div>
      <span
        class="flex items-center justify-center bg-primary rounded-2xl w-12 h-12"
      >
        <slot />
      </span>
    </div>
    <div class="flex-1 mt-0 ml-3 xl:ml-4">
      <h5 class="flex items-center text-base font-semibold">
        {{ heading }}
      </h5>
      <p class="text-md leading-relaxed font-medium">
        {{ text }}
      </p>
    </div>
  </li>
</template>
<script>
export default {
  name: "HeaderTopIcon",
  props: {
    link: {},
    heading: {},
    text: {},
  },
  methods: {
    goToLink() {
      window.open(this.link, "_blank").focus();
    },
  },
};
</script>
