<template>
    <base-button class="border-transparent text-white  hover:bg-primary-lighter hover:text-primary  bg-primary transition" >
        <slot />
    </base-button>
</template>

<script>
    import BaseButton from './BaseButton.vue'
    export default {
        components: { BaseButton },
    }
</script>

<style scoped>

</style>