<template>
  <div class="flex justify-center">
    <div>
      <div class="grid grid-cols-1 lg:grid-cols-3 mt-24">
        <!-- Hero text content -->
        <div class="flex flex-col items-center lg:items-start col-span-2">
          <div>
            <span
              class="inline-block text-xl px-4 py-2 font-medium transform text-white bg-secondary-light rounded-full shadow-md -rotate-3 -ml-4"
            >
              Welcome To The ShibVinci dapp
            </span>
          </div>
          <h1
            class="mt-4 text-dark text-3xl lg:text-5xl font-medium lg:text-left text-center capitalize"
          >
            Our dapp allows you to see the treasury balance, view our nft
            gallery and claim your $SHIV holder rewards.
          </h1>
          <p
            class="max-w-2xl mt-3 text-xl leading-loose text-center text-dark font-medium text-shadow lg:text-left"
          >
            ShibVinci allows investors to increase the value of their $SHIV
            tokens by community-governed trading of NFTs using the ShibVinci DAO
            backed Treasury. 
          </p>

          <!-- Hero buttons -->
          <div
            class="flex flex-col items-center mt-8 overflow-hidden sm:flex-row"
          >
            <default-button xl class="mt-4 md:mt-0 ml-0" @click="goToTreasury">
              <font-awesome-icon icon="coins" class="mr-2" /> View
              Treasury</default-button
            >
            <default-button xl class="mt-4 md:mt-0 ml-4" @click="goToGallery">
              <font-awesome-icon icon="images" class="mr-2" />Go To
              Gallery</default-button
            >
            <secondary-button xl class="mt-4 md:mt-0 ml-4" @click="buyOnUniswap"
              >Buy $SHIV
              <font-awesome-icon icon="long-arrow-right" class="ml-2"
            /></secondary-button>
          </div>
        </div>

        <div class="items-center hidden lg:flex">
          <!-- <img src="/img/dog.png" /> -->
          <div class="pr-8">
            <Vue3Lottie :animationData="AnimationJSON" />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 mt-8 md:space-x-20">
          <daos />
          <buybacks />
      </div>
    </div>
  </div>
</template>

<script>
import Buybacks from '../buybacks/buybacks.vue';
import Daos from '../sidebar/daos.vue';
import { uniswap } from "@/links";
const AnimationJSON = require("@/ShibvinciCharacter.json");

export default {
  name: "Dashboard",
  data(){
      return{
          currentTab:"daos"
      }
  },
  methods:{
    buyOnUniswap() {
      window.open(uniswap, "_blank").focus();
    },
    goToGallery(){
        this.$router.push({path:'/dapp/gallery'})
    },
    goToTreasury(){
        this.$router.push({path:'/dapp/treasury'})
    }
  },
  components: {
    Buybacks,Daos
  },
    setup() {
    return { AnimationJSON };
  },
};
</script>

<style scoped></style>
