<template>
  <div class="mb-8">
    <div class="flex justify-between">
      <input v-model="name" class="" />
      <input v-model="status" />
      <input v-model="link" />
      <primary-button @click="deleteItem"
        ><font-awesome-icon
          icon="spinner-third"
          class="mr-2"
          spin
          v-if="removing"
        />
        remove</primary-button
      >
      <primary-button @click="saveItem"
        ><font-awesome-icon
          icon="spinner-third"
          class="mr-2"
          spin
          v-if="saving"
        />
        save</primary-button
      >
    </div>
  </div>
</template>

<script>
import PrimaryButton from "./buttons/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  name: "TrendingTokenAdminItem",
  props: {
    item: {},
  },
  computed: {
    token_address() {
      return this.item.get("token_address");
    },
  },
  mounted() {
    this.name = this.item.get("name");
    this.status = this.item.get("status");
    this.link = this.item.get("link");
  },
  data() {
    return {
      saving: false,
      removing: false,
      name: "",
      status: "",
      link: "",
    };
  },
  methods: {
    async deleteItem() {
      this.removing = true;
      await this.item.destroy();

      this.$emit("refresh");
        setTimeout(() => {
      this.removing = false;
      }, 1000);
      
    },
    async saveItem() {
      this.saving = true;
      this.item.set("status", this.status);
      this.item.set("name", this.name);
      this.item.set("link", this.link);
      await this.item.save();
      this.$emit("refresh");

      setTimeout(() => {
        this.saving = false;
      }, 1000);
    },
  },
};
</script>

<style scoped></style>
