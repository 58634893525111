import reduce from 'lodash/reduce';

export const formatStringWithCommas = string => {
    const array = string.toString().split(".");
    const arr1 = array[0].split("");
    const arr2 =  reduce(arr1.reverse(), (result, element, index) => {
        if( (index+1) %3 === 0 && index +1 !== arr1.length) {
            return  result  + element.toString() + ',';
        }
        return  result + element.toString() ;
    }, "");
    return arr2.split("").reverse().join("");
}

export const valueAsTrillion = (_string = "0") => {
    if (_string.length > 12) {
      return _string.substring(0, _string.length - 12) + ' T';
    }
    return _string;
};

export const parseNftImageUrl = (url) => {
    return (url.startsWith('ipfs')) ?  url.replace('ipfs://', process.env.VUE_APP_IPFS_PROVIDER) : url;
}

export const formatNumber = (val) => {
    return val.slice(0, val.indexOf(".") + 6);
}