<template>
<div id="treasury" class="bg-white py-20 lg:py-40">
  <div class='max-w-7xl  mx-auto lg:max-w-screen-xl'>
    <div class='grid gap-16 lg:grid-cols-2 xl:grid-cols-11 lg:gap-12 xl:gap-24'>

      <!-- Text content -->
      <div class='flex flex-col justify-center lg:col-span-1 xl:col-span-6 lg:order-2 px-4 sm:px-0'>
        <div>
              <span
                  class="inline-block text-xl px-4 py-2 font-medium text-white bg-secondary-light rounded-full shadow-md -rotate-3 transform -ml-4"
                >
                  The Treasury
                </span>
        </div>
         <h1 class="mt-3.5 font-bold text-4xl text-secondary">
              How will the ShibVinci DAO backed treasury operate?
            </h1>
        <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          The ShibVinci Treasury is formed by a 3% buy & sell fee on $SHIV transactions.
        </p>
         <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          This means the Treasury grows anytime $SHIV tokens are bought or sold, increasing the size of the Treasury for the community governed NFT investments.
        </p>
         <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          Voting rights are gained by purchasing ShibVinci tokens - as long as you are a ShibVinci token holder, you have a right to vote on what NFT’s the Treasury fund will purchase, as well as when the treasury sells the NFT’s to make profit.
        </p>
         <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          This makes ShibVinci a DAO backed Treasury where investments from the Treasury are decided 100% by the holders of $SHIV.
        </p>
         <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          50% of the profits from the Treasury NFT investments are used to buy back the ShibVinci token from the circulating supply which means there will be less tokens available, therefore increasing the value of the tokens you hold.
        </p>
         <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
          The other 50% of Treasury profits are sent to the DAPP Wallet, where $SHIV token holders can withdraw their NFT profits in ETH, or reinvest in $SHIV.
        </p>

      </div>

      <!-- Featured teachers section -->
      <div class='grid w-full gap-10 mx-auto sm:gap-8 lg:col-span-1 xl:col-span-5 lg:mt-20 sm:grid-cols-2 lg:gap-4 xl:gap-8 lg:order-1 sm:max-w-none sm:mx-0'>

        <!-- Teacher 1 -->
        <div class='transform lg:-translate-y-20'>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' src='https://lh3.googleusercontent.com/Q4uXff5hD6T91FlaDiqZTpMu-kEgwx6IcUHXsWF_Moq5u6VOvfqKuIXN2_StL78LNiA1YW3e16vnrLq_zqvfOMtK7PLy9AcKGxWr=w600' alt='About team 1'>
          </div>
          <div class='pl-2 sm:pl-0 mt-3.5 text-lg sm:text-center'>
            <p class='font-semibold tracking-wide text-secondary'>
              Bored Ape Yatch Club
            </p>
            <p class='text-base font-medium text-secondary'>
              Floor: 99.99 ETH
            </p>
          </div>
        </div>

        <!-- Teacher 2 -->
        <div>
          <div class=' transform aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' src='https://lh3.googleusercontent.com/g3E6WsnZPD4zpC6fHcgBO8AEVabNly2n3kHH4_0t2X01R7f4IugJG8LflcsMSG1-_r6grfm5QuyzcZF5nPbTvY4ywFVNa-useJr5=w600' alt='About team 2'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-secondary'>
              Doodles
            </p>
            <p class='text-base font-medium text-secondary'>
              Floor: 13.79 ETH
            </p>
          </div>
        </div>

        <!-- Teacher 3 -->
        <div class='transform lg:-translate-y-20'>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' src='https://lh3.googleusercontent.com/Swbn4NzXbxm5OwzBSAwVw3w0t6HRloIzNTj9vE1iGLIk3gNJbqe_zvH-x3HeAqypikiR4MKWBJ89YAgJHrDodZij7XZano_EF9pyxnE=w600' alt='About team 3'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-secondary'>
              Cool Cats NFT
            </p>
            <p class='text-base font-medium text-secondary'>
              Floor: 10.6 ETH
            </p>
          </div>
        </div>

        <!-- Teacher 4 -->
        <div>
          <div class='aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4'>
            <img class='object-cover shadow-md rounded-3xl' src='https://lh3.googleusercontent.com/q9Nw3wE5EM0hB_qhX7L0w6ibqE7r876FHUmFe9fe8BR8Bd1XsaaIujyrXoiw6Rs1kmqv917EPYG5-TP597cCi123H4O13Lm4rkOiug=s0' alt='About team 4'>
          </div>
          <div class='pl-2 sm:pl-0 sm:text-center mt-3.5 text-lg'>
            <p class='font-semibold tracking-wide text-secondary'>
              World of Women
            </p>
            <p class='text-base font-medium text-secondary'>
              Floor: 10.9 ETH
            </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/vue/outline'

export default {
  components: { PrimaryButton, GlobeAltIcon, LightningBoltIcon, ScaleIcon },
  name:"Backstory",
};
</script>

<style lang="scss" scoped></style>
