<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <div class="bg-white max-w-3xl mx-auto p-8 mt-4 rounded-2xl">
    <p v-if="notAuthenticated">
      Invalid Admin Address:
      <a @click="logout" class="text-blue-500 font-medium cursor-pointer"
        >Logout</a
      >
    </p>
    <div class="space-y-8 divide-y divide-gray-200" v-else-if="authenticated">
      <primary-button @click="active='marketing'"> Marketing</primary-button>
      <primary-button @click="active='daos'" class="ml-6">Daos</primary-button>
     <div v-if="active==='marketing'">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Send Marketing Funds
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Enter the Wallet Address, Amount and Description below
            </p>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              v-if="from !== treasuryAddress"
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="first-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Address
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  v-model="address"
                  name="address"
                  id="address"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Amount
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="amount"
                  type="text"
                  name="amount"
                  id="amount"
                  class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div
              v-if="from !== treasuryAddress"
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="email"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  id="description"
                  name="description"
                  type="text"
                  v-model="description"
                  class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md sm:max-w-xs"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5"
        >
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Send Funds From
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Select the address to send the funds from
            </p>
          </div>
          <div class="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div class="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-notifications">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Contract Addresses
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="max-w-lg">
                      <p class="text-sm text-gray-500">
                        Please Select an address
                      </p>
                      <div class="mt-4 space-y-4">
                        <div class="flex items-center">
                          <input
                            id="push-everything"
                            name="push-notifications"
                            v-model="from"
                            type="radio"
                            v-bind:value="marketingAddress"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            for="push-everything"
                            class="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Marketing Wallet ({{ marketingBalance }} ETH)
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input
                            id="push-email"
                            name="push-notifications"
                            v-model="from"
                            type="radio"
                            v-bind:value="devAddress"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            for="push-email"
                            class="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Dev Wallet ({{ devBalance }} ETH)</label
                          >
                        </div>
                        <div class="flex items-center">
                          <input
                             id="push-treasury"
                            name="push-treasury"
                            v-model="from"
                            type="radio"
                            v-bind:value="treasuryAddress"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                          />
                          <label
                            for="push-treasury"
                            class="ml-3 block text-sm font-medium text-gray-700"
                          >
                            Treasury ({{ treasuryBalance }} ETH)</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <button
            type="button"
            v-if="!connected_address"
            @click="connect"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Connect
          </button>
          <button
            type="button"
            v-else-if="connected_network !== chainId"
            @click="switchNetwork"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Switch Network
          </button>
          <button
            type="submit"
            v-else
            @click="send"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xl font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {{ loading ? "Sending Transaction ..." : "Send Eth" }}
          </button>
        </div>
        <p v-if="error" class="mt-4 text-red-500 text-right">{{ error }}</p>
        <a v-if="treasuryAddressHash" :href="'https://etherscan.io/tx/'+treasuryAddressHash" class="mt-4 text-blue-500 text-right">View on Etherscan</a>
      </div>

      <div
        class="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5"
      >
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Marketing History
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Total Spent on marketing: {{ marketingTotal }} ETH
          </p>
        </div>
        <send-marketing-item
          v-for="(marketingItem, index) in marketingItems"
          :key="index"
          :item="marketingItem"
        />
      </div>
     </div>
     <div v-else>
       <manage-daos />
     </div>
    </div>
    <p v-else>loading...</p>
  </div>
</template>
<script>
import Web3 from "web3";
import Moralis from "moralis";
import SendMarketingItem from "../components/SendMarketingItem.vue";
import forEach from "lodash/forEach";
import axios from 'axios'
import PrimaryButton from '../components/buttons/PrimaryButton.vue';
import ManageDaos from '@/components/ManageDaos'
export default {
  components: { SendMarketingItem, PrimaryButton, ManageDaos },
  name: "SendMarketing",
  data() {
    return {
      active: 'marketing',
      marketingBalance: 0,
      devBalance: 0,
      treasuryBalance: 0,
      address: "",
      amount: "",
      description: "",
      connected_address: "",
      connected_network: "",
      from: this.marketingAddress,
      loading: false,
      authenticated: false,
      marketingItems: [],
      notAuthenticated: false,
      error: "",
      treasuryAddressHash:""
    };
  },
  computed: {
    admins() {
      return [
        "0x40331ae64547f292a69fC60Affba5CBA7F515Df9".toLowerCase(),
        "0xc3fFb5A146cB21F57F2abCc87d8b00D5C18429Ac".toLowerCase(),
        "0x03fa022c95B233F6dD26Ada1D25ad381677569bf".toLowerCase(),
        "0xe3e6326cAacC9744a5502Df5F3C28a09feC62064".toLowerCase(),
        "0x6Bc10225f7223366c0c650507393c3d6CD5890a2".toLowerCase(),
      ];
    },
    marketingTotal() {
      let t = 0.0;
      forEach(this.marketingItems, (item) => {
        t += parseFloat(item.get("amount"));
      });
      return t.toFixed(2);
    },
  },
  setup() {
    return {
      chainId: parseInt(process.env.VUE_APP_CHAIN),
      marketingAddress: process.env.VUE_APP_MARKETING_WALLET,
      devAddress: process.env.VUE_APP_DEV_WALLET,
      treasuryAddress: process.env.VUE_APP_TREASURY_WALLET,
    };
  },
  async mounted() {
    await Moralis.start({
      appId: process.env.VUE_APP_MORALIS_APP_ID,
      serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
    });
    const currentUser = Moralis.User.current();
    if (currentUser) {
      // do stuff with the user
      this.authenticate(currentUser);
    } else {
      // show the signup or login page
      const user = await Moralis.authenticate({
        signingMessage: "Signin with ShibVinci Admin Address",
      });
      this.authenticate(user);
    }

    //await this.loadPage();
  },
  methods: {
    logout() {
      Moralis.User.logOut();
      window.location.reload();
    },
    authenticate(user) {
      const authenticated = user.get("accounts")[0];

      if (this.admins.includes(authenticated)) {
        this.authenticated = true;
        this.loadPage();
      } else {
        this.notAuthenticated = true;
      }
    },
    async loadPage() {
      const Item = Moralis.Object.extend("ShibVinciMarketing");
      const query = new Moralis.Query(Item);
      query.descending("createdAt");
      this.marketingItems = await query.find();

      let subscription = await query.subscribe("ShibVinciMarketing");
      subscription.on("open", () => {
        console.log("subscription opened");
      });
      subscription.on("create", (object) => {
        this.marketingItems.unshift(object);
      });

      await this.loadBalances();
      try {
        if (typeof window.ethereum !== "undefined") {
          window.ethereum.autoRefreshOnNetworkChange = false;
          this.loadWeb3();

          /* Case 2 - User switch account */
          window.ethereum.on("accountsChanged", async () => {
            this.loadWeb3();
          });
          /* Case 3 - User switch network */
          window.ethereum.on("chainChanged", async () => {
            this.loadWeb3();
          });
        }
      } catch (e) {
        console.error("Error, load Web3: ", e);
      }
    },
    async send() {
      this.loading = true;
      this.error = false;

      const web3 = new Web3(window.ethereum);

      if (this.from === this.treasuryAddress) {
        try {
          const req = await axios.get(
            process.env.VUE_APP_API_SERVER+"/transfer?key=c3fFb5bCcfC60Affba5CBA7F5158429Ac022c9593c3d6CD589q3974gc68hntgxq398Ff4&wei="+web3.utils.toWei(this.amount).toString()
          );
          this.treasuryAddressHash = req.data.transactionHash
          this.loading = false;
          return;
        } catch (err) {
          this.loading = false;
          this.error = err;
          return;
        }
      }

      
      const contract = new web3.eth.Contract(
        require("../contracts/artifacts/PaymentSplitter.json").abi,
        this.from
      );
      try {
        const gas = await contract.methods
          .sendEth(this.address, web3.utils.toWei(this.amount).toString())
          .estimateGas({
            from: this.connected_address,
          });
        const transaction = await contract.methods
          .sendEth(this.address, web3.utils.toWei(this.amount).toString())
          .send({
            from: this.connected_address,
            gas,
          });

        const Item = Moralis.Object.extend("ShibVinciMarketing");
        const item = new Item();

        item.set("address", this.address);
        item.set("amount", this.amount);
        item.set("description", this.description);
        item.set("ref", transaction.transactionHash);

        await item.save();
        this.address = "";
        this.amount = "";
        this.description = "";
        await this.loadBalances();
        this.loading = false;
      } catch (err) {
        if (err.message.includes("Invalid admin address")) {
          this.error = "Invalid Admin Address";
        } else {
          this.error = err.message;
        }
        this.loading = false;
        return;
      }
    },
    async loadWeb3() {
      const web3 = new Web3(window.ethereum);
      this.connected_network = await web3.eth.getChainId();
      const addresses = await web3.eth.getAccounts();
      this.connected_address = addresses[0];
    },
    async loadBalances() {
      const web3 = new Web3(process.env.VUE_APP_SPEEDY_NODE);
      const rawBalance = await web3.eth.getBalance(
        process.env.VUE_APP_MARKETING_WALLET
      );
      this.marketingBalance = new Number(web3.utils.fromWei(rawBalance));
      const rawBalanceDev = await web3.eth.getBalance(
        process.env.VUE_APP_DEV_WALLET
      );
      this.devBalance = new Number(web3.utils.fromWei(rawBalanceDev));

      const rawBalanceTreasury = await web3.eth.getBalance(
        process.env.VUE_APP_TREASURY_WALLET
      );
      this.treasuryBalance = new Number(web3.utils.fromWei(rawBalanceTreasury));
    },
    async connect() {
      const web3 = new Web3(window.ethereum);
      await web3.currentProvider.request({
        method: "eth_requestAccounts",
      });
    },
    async switchNetwork() {
      const web3 = new Web3(window.ethereum);
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: process.env.VUE_APP_NETWORK,
          },
        ],
      });
    },
  },
};
</script>
