<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-secondary z-30 mb-4 lg:mb-0 ">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p class="font-medium text-white">
          <span class="inline"> Big news! Our NFT gallery is now viewable in 3D & VR.  </span>
          <span class="block sm:ml-2 sm:inline-block">
            <a href="https://oncyber.io/spaces/udrNKKT0SIeDCftrr3tN" target="_blank" class="text-white font-bold underline"> Learn more <span aria-hidden="true">&rarr;</span></a>
          </span>
        </p>
      </div>
 
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    XIcon,
  },
}
</script>