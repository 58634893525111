<template>
<div class=' px-4 block sm:px-6'>
    <mobile-menu :open="mobileMenuOpen" @close="close"/>
  <!-- Container -->
  <div class='relative  py-5 mx-auto border-b border-purple-200/30'>
    <div class='flex items-center justify-between'>

      <!-- Site branding -->
      <div class="flex-grow-0 flex-shrink-0 w-72 flex items-center">
        <a href="index.html">
          <img src="/img/logo-2.png" alt="Bright" class="h-auto"/>
        </a>
        <ul class="flex text-secondary-lighter ml-4 mr-8 sm:mr-0">
          <li @click="openTwitter" class="cursor-pointer"><font-awesome-icon :icon="['fab', 'twitter']" size="2x" class="mr-2 sm:mr-3"/></li>
          <li @click="openTg" class="cursor-pointer"><font-awesome-icon :icon="['fab', 'telegram']" size="2x"/></li>
        </ul>
      </div>

      <!-- Contact information -->
      <ul class='hidden lg:flex ml-8 lg:space-x-6 xl:space-x-16'>
       <header-top-icon 
        heading="Chart"
        text="View $SHIV chart on DexTools"
        :link="chart"
       >
         <ChartBarIcon class="h-6 w-6"/>
       </header-top-icon>
       <header-top-icon 
        heading="Trade"
        text="Buy $SHIV on Uniswap"
        :link="uniswap"
       >
         <SwitchHorizontalIcon class="h-6 w-6"/>
       </header-top-icon>
       <header-top-icon 
        heading="Whitepaper"
        text="View the whitepaper"
        :link="whitepaper"
       >
         <DocumentTextIcon class="h-6 w-6"/>
       </header-top-icon>        
      </ul>
      <div class="lg:hidden ">
        <primary-button xl  @click="open">
          <MenuIcon class="h-5 w-5"/>
        </primary-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { MenuIcon, ChartBarIcon, SwitchHorizontalIcon, DocumentTextIcon} from "@heroicons/vue/outline";
import SecondaryButton from "../../../../components/buttons/SecondaryButton.vue";
import {whitepaper, telegram, uniswap, etherscan,  chart, twitter} from "@/links"
import HeaderTopIcon from './HeaderTopIcon.vue';
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import MobileMenu from '../MobileMenu.vue';
const navigation = [
  { name: "whitepaper", href: whitepaper },
  { name: "contract", href: etherscan },
  { name: "uniswap", href: uniswap },
  { name: "chart", href: chart },
  { name: "telegram", href: telegram },
  { name: "twitter", href: twitter },
];

export default {
  name: "HeaderTop",
  components: {
     ChartBarIcon, SwitchHorizontalIcon, DocumentTextIcon,
    HeaderTopIcon, MenuIcon, PrimaryButton,
    MobileMenu
  },
  setup() {
    return {
      navigation, whitepaper, chart, uniswap
    };
  },
  data(){
    return {
      mobileMenuOpen: false
    }
  },
  methods:{
    launchDapp(){
      this.$router.push({path:'/dashboard'})
    },
    close(){
      this.mobileMenuOpen = false
    },
    open(){
      this.mobileMenuOpen = true
    },
    openTg(){
          window.open(telegram, "_blank").focus();
    },
    openTwitter(){
    window.open(twitter, "_blank").focus();
    }
  }
};
</script>

<style lang="scss" scoped></style>
