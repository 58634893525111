<template>
  <div class="bg-white relative">
    <div class="animated-scene z-10">
      <div class="animated-scene__frame hidden md:block">
        <img
          class="animated-scene__frame__img"
          src="/img/fullscreen-bg.jpg"
        />
      </div>
    </div>
    <div class="animated-scene animated-scene_overlay z-20 ">&nbsp;</div>

    <div class="relative z-30">
      <div class="">
        <header-top/>
        <navigation />
      </div>
      <slot />
      
    </div>
  </div>
</template>

<script>
// https://www.tailwindawesome.com/resources/bright/demo
import HeaderTop from "../modules/website/components/header/HeaderTop.vue";
import Navigation from '../modules/website/components/header/Navigation.vue';
// import vFooter from "../modules/website/components/footer/Footer.vue";
export default {
  name: "WebsiteLayout",
  components: { HeaderTop, Navigation },
};
</script>

<style scoped>
@keyframes infiniteScrollBg {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
}

.animated-scene {
  width: 100vw;
  height: 100vh;
  position: fixed;
  min-height: 400px;
}

.animated-scene__frame {
  width: 100%;
  position: relative;
  animation: infiniteScrollBg 15s infinite linear;
}


.animated-scene__frame:after {
  content: "";
  height: 500%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* background-color: rgba(255, 149, 1); */
  background-image: url("/img/fullscreen-bg.jpg");
  background-size: 100% 20%;
}

.animated-scene__frame__img {
  width: 100%;
}

.animated-scene_overlay {
    /* background: rgba(255, 149, 1); */
  background: linear-gradient(
    to top,
    rgba(0,38, 73, 0.95),
    rgba(0,38, 73, 1)
  );
}
</style>
