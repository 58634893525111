<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>


export default {
  name: "App",
  components: {
  
  },
  async mounted() {
     await this.$moralis.start({
      serverUrl: process.env.VUE_APP_MORALIS_SERVER_URL,
      appId: process.env.VUE_APP_MORALIS_APP_ID,
    });

    // TODO catch error on page load
    // TODO tweak page animations https://learnvue.co/2021/01/4-awesome-examples-of-vue-router-transitions/
    this.pageReady = true;
  },
  data() {
    return {
      pageReady: false,
    };
  },
};
</script>

<style>
body {
  background: black;
}
</style>
