<template>
  <div id="tokenomics" class="py-12 sm:py-32">
    <!-- Section header text -->
    <div>
        <h1 class="font-bold  text-5xl md:text-7xl text-white text-center">
          <span class="relative block">
            <span class="relative">
              <img
                class="absolute inset-0 transform translate-y-12 md:translate-y-14"
                src="images/underline-simple-orange.svg"
              />
              <span class="relative">Tokenomics</span>
            </span>
          </span>
        </h1>
      <p
        class="max-w-3xl mx-auto mt-6  text-xl text-white leading-relaxed text-center "
      >
        Token Symbol: <span class="font-written  text-xl">$SHIV</span>
      </p>
       <p
        class="max-w-3xl mx-auto mt-2  text-xl text-white leading-relaxed text-center "
      >
        Launching: <span class="font-written  text-xl">15th February 2022</span>
      </p>
          <p
        class="max-w-3xl mx-auto mt-2  text-xll text-white leading-relaxed text-center "
      >
        Ethereum Address: <span class="font-written  truncate max-w-sm overflow-hidden block px-4 text-xl">{{ (CONRACT_ADDRESS) ? CONRACT_ADDRESS :  'TBC'}}</span>
      </p>
       <p
        class="max-w-3xl mx-auto mt-2 text-xl text-white leading-relaxed text-center "
      >
       Intial Supply: <span class="font-written  text-xl">1,000,000,000,000,000,000</span>
      </p>
  
    </div>

    <!-- Stats -->
    <div
      class="mt-12 sm:grid sm:grid-cols-4 sm:gap-x-6 md:gap-x-8 lg:gap-x-12 sm:gap-y-12 gap-y-16 max-w-5xl mx-auto"
    >
      <!-- Stat 1 -->
      <div class="text-center">
        <h4 class="leading-none text-white font-written text-8xl lg:text-9xl">
          1%
        </h4>
        <p
          class="mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50"
        >
          Reflections
        </p>
      </div>

      <!-- Stat 2 -->
      <div class="mt-12 text-center sm:mt-0">
        <h4 class="leading-none text-white font-written text-8xl lg:text-9xl">
          3%
        </h4>
        <p
          class="mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50"
        >
          Treasury
        </p>
      </div>

      <!-- Stat 3 -->
      <div class="mt-12 text-center sm:mt-0">
        <h4 class="leading-none text-white font-written text-8xl lg:text-9xl">
          3%
        </h4>
        <p
          class="mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50"
        >
          Marketing
        </p>
      </div>

      <!-- Stat 4 -->
      <div class="mt-12 text-center sm:mt-0">
        <h4 class="leading-none text-white font-written text-8xl lg:text-9xl">
          3%
        </h4>
        <p
          class="mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50"
        >
          Development
        </p>
      </div>

      <div class="flex justify-center col-span-4">
        <div class="border-4 border-white px-8 py-4 mt-12 sm:mt-4 text-white rounded-xl text-center">
          <h2 class="text-4xl md:text-7xl font-written">Total Tax</h2>
          <h4 class=" text-4xl md:text-6xl font-medium">10%</h4>
          <p class="mt-2">*Slippage: 13% or higher</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CONRACT_ADDRESS} from '@/links.js';
export default {
  name: "Tokenomics",
  setup(){
    return {
      CONRACT_ADDRESS
    }
  }
};
</script>

<style lang="scss" scoped></style>
