import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faHome, faMobileAlt, faPawAlt, faFarm, faStoreAlt, faComet, faEgg,
    faGlobe, faStickyNote, faCircleNotch, faExternalLink, faSave, faAngleLeft,
    faSignIn, faCheck, faArrowDown, faLevelUpAlt, faQuestionCircle, faArrowUp,
    faLongArrowRight, faCoins, faFire, faRocketLaunch, faImages, faUsers, faRepeat,
    faSpinnerThird, faTreasureChest, faDollarSign
} from '@fortawesome/pro-solid-svg-icons'
import {
    faTwitter, faTelegram
} from '@fortawesome/fontawesome-free-brands'

library.add( 
    faHome, faMobileAlt, faPawAlt, faFarm, faStoreAlt, faComet, faEgg,
    faGlobe, faStickyNote, faTelegram, faTwitter, faCircleNotch, faExternalLink,
    faSave, faAngleLeft, faSignIn, faCheck, faArrowDown, faLevelUpAlt, faQuestionCircle,
    faArrowUp, faLongArrowRight, faCoins, faFire,faRocketLaunch, faImages, faUsers, faRepeat,
    faSpinnerThird, faTreasureChest,faDollarSign
    )
