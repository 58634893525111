<template>
  <div class="pt-8">
      <div>
      <div class="flex justify-between">
        <label for="email" class="block text-xl font-medium text-gray-500"
          >Add New DAO</label
        >
      </div>
      <div class="mt-1 flex justify-between">
        <input
          type="text"
          name="name"
          id="name"
          class="
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-gray-300
            rounded-md
          "
          v-model="newDaoName"
          placeholder="name"
          aria-describedby="email-optional"
        />
         <input
          type="text"
          name="link"
          id="link"
          class="
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-gray-300
            rounded-md
          "
          v-model="newDaoLink"
          placeholder="link"
          aria-describedby="email-optional"
        />
      </div>
    </div>
    <primary-button @click="addNewToken" class="mt-2 mb-12 ">     <font-awesome-icon icon="spinner-third" class="ml-2" spin v-if="loading" /> Add Dao</primary-button>
    <manage-daos-item
      v-for="(item, key) in tokens"
      :key="key"
      :item="item"
      @refresh="getTrendingTokens"
    />
    
  </div>
</template>

<script>
import Moralis from "moralis";
import reduce from "lodash/reduce";
import ManageDaosItem from "./ManageDaosItem.vue";
export default {
  name: "ManageDaos",
  components: {
    ManageDaosItem,
  },
  async mounted() {
    this.getTrendingTokens();
  },
  data() {
    return {
      tokens: [],
      newDaoName:"",
      newDaoLink:"",
      loading: false
    };
  },
  methods: {
    async getTrendingTokens() {
      const obj = Moralis.Object.extend("ShibVinciDaos");
      const query = new Moralis.Query(obj);
      const q = await query.find();
      this.tokens = q;
    },
    async addNewToken(){
        this.loading = true;
        const obj = Moralis.Object.extend("ShibVinciDaos");
        const item = new obj();
        item.set('name', this.newDaoName);
        item.set('link', this.newDaoName);
        item.set('status', 'open');
        await item.save();
        this.newDaoName = ""
        this.newDaoLink = ""
        this.getTrendingTokens();

        this.loading = false;
    }
  },
};
</script>

<style scoped></style>
