<template>
<a :href="'#'+link">
          <div class="relative p-0.5 group">
            <span class="relative text z-10 text-xl font-medium duration-300 ease-in-out text-white">{{text}}</span>
            <img src="/images/underline-simple-orange.svg" class="w-20 opacity-0 mx-auto transition" />
          </div>
        </a>
</template>

<script>
    export default {
        name:"NavigationItem",
        props:{
            link:{},
            text:{}
        }
    }
</script>

<style scoped>
.text:hover+img {
    opacity: 1;
}
</style>