<template>
  <div>
    <svg class="svg">
      <line id="line1"  class="line"/>
    </svg>
    <svg class="svg">
      <line id="line2" class="line"/>
    </svg>
    <svg class="svg">
      <line id="line3" class="line"/>
    </svg>
    <svg class="svg">
      <line id="line4" class="line"/>
    </svg>
    <svg class="svg">
      <line id="line5" class="line"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "RoadmapLines",
  mounted() {
    this.drawLine("line1", "step1", "step2");
    this.drawLine("line2", "step2", "step3");
    this.drawLine("line3", "step3", "step4");
    this.drawLine("line4", "step4", "step5");
    this.drawLine("line5", "step5", "step6");
  },
  methods: {
    drawLine(lineId, sourceId, targetId) {
      var line = document.getElementById(lineId);
      var div1 = document.getElementById(sourceId);
      var div2 = document.getElementById(targetId);

      var x1 = div1.offsetLeft + div1.clientWidth / 2;
      var y1 = div1.offsetTop + div1.clientHeight / 2;
      var x2 = div2.offsetLeft + div2.clientWidth / 2;
      var y2 = div2.offsetTop + div2.clientHeight / 2;

      line.setAttribute("x1", x1)
      line.setAttribute("y1", y1)
      line.setAttribute("x2", x2)
      line.setAttribute("y2", y2);
    },
  },
};
</script>

<style  scoped>
.svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.line{
  stroke-width:8px;
  stroke:white;
  opacity: 0.5;
}

.point{
  width: 10px;
  height: 10px;
  background: red;
  position: absolute;
}</style>
