<template>
  <treasury-item
    heading="Treasury Balance"
    :balance="treasury.balanceUsd"
    :subbalance="treasury.balance"
    :loading="treasury.fetchingData"
    img="/img/frame-1.png"
  />
</template>

<script setup>
import TreasuryItem from "./TreasuryItem.vue";
import useTreasuryBalance from "@stores/useTreasuryBalance";

const treasury = useTreasuryBalance();
if (!treasury.gotData && !treasury.fetchingData) {
  treasury.fetchData();
}
</script>

<style lang="scss" scoped></style>
