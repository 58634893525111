<template>
  <div class="flex justify-center">
    <div>


      <div class="grid  grid-cols-1 lg:grid-cols-2">
        <div class="pt-4 md:pt-12">
            <h1
            class="mt-12 text-dark text-3xl lg:text-5xl font-medium lg:text-left text-center capitalize"
          >
            The ShibVinci Treasury
          </h1>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5 ">
            The ShibVinci Treasury is formed by a 3% buy & sell fee on $SHIV
            transactions.
          </p>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
            This means the Treasury grows anytime $SHIV tokens are bought or
            sold, increasing the size of the Treasury for the community governed
            NFT investments.
          </p>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
            50% of the profits from the Treasury NFT investments are used to buy
            back the ShibVinci token from the circulating supply which means
            there will be less tokens available, therefore increasing the value
            of the tokens you hold.
          </p>
          <p class="max-w-xl mt-4 text-xl leading-relaxed text-dark md:mt-5">
            The other 50% of Treasury profits are sent to the DAPP Wallet, where
            $SHIV token holders can withdraw their NFT profits in ETH, or
            reinvest in $SHIV.
          </p>
        </div>
        <div class="mt-4 pb-12 sm:pb-16 xl:pl-12">
          <treasury-balance />
          <treasury-item
            heading="Total Nfts"
            :balance="nfts.nfts.length"
            :subbalance="nfts.uniqueProjects.length + ' Collections'"
            img="/img/frame-2.png"
            :loading="nfts.fetchingData"
          />
          <treasury-item
            heading="Est. NFTs Value"
            :balance="nfts.estFloorValueUsd"
            :subbalance="nfts.estFloorValue + ' ETH'"
            img="/img/frame-3.png"
            :loading="nfts.fetchingData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreasuryItem from "./TreasuryItem.vue";
import TreasuryBalance from "./TreasuryBalance.vue";
import useNfts from "@stores/useNfts";

export default {
  components: { TreasuryItem, TreasuryBalance },
  name: "Treasury",
  computed: {}, // 0xffa9B09AeBE228FBb9926b14E77065F10f9A9460
  setup() {
    const nfts = useNfts();

    if (!nfts.gotData && !nfts.fetchingData) {
      nfts.fetchData();
    }
    return { nfts };
  },
};
</script>

<style scoped></style>
