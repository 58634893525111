import axios from 'axios';
import {
    defineStore
} from 'pinia'

export default defineStore('ethUsdPrice', {
    state:()=>({
        value: 0,
        gotData: false,
        fetchingData: true,
        error: false,
    }),
    actions:{
        async fetchData(){
            this.fetchingData = true;
            try {
                const req = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=weth&vs_currencies=usd');
                this.value = parseInt(req.data.weth.usd);
                this.fetchingData = false;
                this.gotData = true;
            }catch(err){
                console.error(err);
                this.gotData = true;
                this.fetchingData = false;
                this.error = err.message;
            }
        }
    }
});