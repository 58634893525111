<template>
  <section id="about" class="px-4 overflow-hidden bg-white pb-24 lg:pb-52 ">
    <!-- Container -->
    <div class="max-w-screen-xl mx-auto">
      <div class="max-w-3xl mx-auto">
        <h1 class="md:mt-24 mt-12 font-bold text-5xl md:text-7xl text-secondary text-center">
          <span class="relative block">
            <span class="relative">
              <img
                class="absolute inset-0 transform translate-y-9 sm:translate-y-10 md:translate-y-14"
                src="images/underline-simple-orange.svg"
              />
              <span class="relative">What Is ShibVinci</span>
            </span>
          </span>
        </h1>
        <p class="mt-12 text-xl text-dark text-center">
         SHIBVINCI is a project that unites the two parts of crypto that have transcended into pop culture; meme coins and NFTs. 
        </p>
        <p class="mt-6 text-xl text-dark text-center">
          For the first time, SHIBVINCI allows investors to hold a meme coin with a community treasury used to buy, own and trade NFTs, sharing profits with the holders.
        </p>
        <p class="mt-6 text-xl text-dark text-center">
          ShibVincis $SHIV, is a governance token that gives holders voting rights. This means the community governs the decisions on what projects to invest in, how long to hold, when to sell and can choose to either take profits in ETH or more SHIV.
        </p>
        <div class="mt-8 rounded-xl shadow-xl overflow-hidden">
          <div id="video" style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.mediadelivery.net/embed/22994/6db3e39e-95b6-4230-a1bb-873f1f8a1666" loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
        </div>
      </div>

      <!-- Block 1 -->
      <div
        class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8"
      >
        <!-- Block text content -->
        <div
          class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left"
        >
          <div>
            <h1 class="md:mt-3.5 mt-16 font-bold text-3xl md:text-4xl text-secondary text-center lg:text-left">
              What is a governance token?
            </h1>
            <p class="max-w-xl mt-3 text-xl text-dark text-center lg:text-left">
              Governance tokens are tokens that represent voting power on a
              blockchain project. They represent the main utility token of DeFi
              protocols since they distribute powers and rights to users via
              tokens. With these tokens, one can create and vote on proposals.
            </p>
            <p class="max-w-xl mt-3 text-xl text-dark text-center lg:text-left">
              $SHIV will be our ShibVinci Governance token.
            </p>
          </div>
        </div>

        <!-- Block graphics -->
        <div
          class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
        >
          <!-- Blob background decoration on large screens -->
          <div class="hidden lg:block">
            <img
              src="images/blob-light-orange.svg"
              class="absolute inset-0 w-full h-full transform lg:scale-135"
              alt=""
            />
          </div>

          <!-- Grid background decoration on small screens -->
          <img
            src="images/dots-large-strip.svg"
            class="absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80"
            alt=""
          />

          <div
            class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto"
          >
            <div class="relative block w-full">
              <!-- Corner dots decoration -->
              <img
                class="absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20 opacity-70"
                src="images/dots.png"
                alt=""
              />

              <!-- Block image -->
              <figure class="aspect-w-12 aspect-h-10 md:order-1">
                <video
                  src="/videos/governance2.mp4"
                  class="object-cover object-center w-full h-full shadow-xl rounded-3xl bg-white p-2"
                  alt="About 01"
                  autoplay
                  loop
                  muted
                />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <!-- Block 2 -->
      <div
        class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8"
      >
        <!-- Block text content -->
        <div
          class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:text-left"
        >
          <div>
            <h1 class="md:mt-3.5 mt-16 font-bold text-4xl text-secondary text-center lg:text-left">
              What is a DAO?
            </h1>
            <p class="max-w-xl mt-3 text-xl text-dark text-center lg:text-left">
              A Decentralized Autonomous Organization (DAO) is an entity with no
              central leadership. Decisions get made from the bottom-up,
              governed by a community organized around a specific set of rules
              enforced on a blockchain.
            </p>
            <p class="max-w-xl mt-3 text-xl text-dark text-center lg:text-left">
              The ShibVinci Treasury will be a DAO backed Treasury where
              investments from the Treasury are decided 100% by the community.
            </p>
          </div>
        </div>

        <!-- Block graphics -->
        <div
          class="relative order-1 w-full max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
        >
          <!-- Blob background decoration on large screens -->
          <div class="hidden lg:block">
            <img
              src="images/blob-light-blue.svg"
              class="absolute inset-0 w-full h-full transform lg:scale-135"
              alt=""
            />
          </div>

          <!-- Grid background decoration on small screens -->
          <img
            src="images/dots-large-strip.svg"
            class="absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80"
            alt=""
          />

          <div
            class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mx-0"
          >
            <div class="relative block w-full">
              <!-- Corner dots decoration -->
              <img
                class="absolute z-10 hidden w-40 transform lg:block -top-20 -right-20 xl:w-48 xl:-top-20 xl:-right-20 opacity-70"
                src="images/dots.png"
                alt=""
              />

              <!-- Block image -->
              <figure class="aspect-w-12 aspect-h-10 md:order-1">
                <video
                  src="/videos/dao2.mp4"
                  class="object-cover object-center w-full h-full shadow-xl rounded-3xl bg-white p-2"
                  alt="About 02"
                  autoplay
                  loop
                  muted
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style lang="scss" scoped></style>
