<template>
  <div class="relative mx-auto mb-8 p-4 mt-8 bg-white  shadow rounded-lg " style="width: 340px; height: 200px">
    <div class="  h-full text-center flex flex-col justify-center">
      <dt class="order-1 text-xl leading-6 font-medium text-gray-600 ">
        {{ heading }}
      </dt>
        <dd class="order-3 text-lg font-extrabold text-gray-500">
        <span v-if="! loading">({{ subbalance }})</span>
        <font-awesome-icon
          v-else
          icon="spinner-third"
          spin
        />
      </dd>
      <dd class="order-2 text-5xl font-extrabold text-primary">
        <span v-if="! loading">{{ balance }}</span>
        <font-awesome-icon
          v-else
          icon="spinner-third"
          spin
        />
      </dd>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreasuryItem",
  props: {
    heading: {},
    balance: {},
    subbalance: {},
    img: {},
    loading: {}
  },
};
</script>

<style scoped>
.frame-border {
}
</style>
