<template>
 <div class="relative bg-white py-16 sm:py-24 lg:py-32">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <p class="mt-2 text-3xl font-extrabold text-secondary tracking-tight max-w-2xl mx-auto sm:text-4xl capitalize">HOW WILL THE SHIBVINCI DAO BACKED TREASURY OPERATE?</p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <GlobeAltIcon class="h-6 w-6 text-white" aria-hidden="true"  />
                  </span>
                </div>
                <p class="mt-5 text-base text-gray-500">
The ShibVinci Treasury is formed by a 4% buy & sell fee.  This means the Treasury grows anytime $SHIV tokens are bought or sold, increasing the size of the Treasury for the community governed NFT investments.
</p>
              </div>
            </div>
          </div>
           <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <LightningBoltIcon  class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <p class="mt-5 text-base text-gray-500">Voting rights are gained by purchasing ShibVinci tokens - as long as you are a ShibVinci token holder, you have a right to vote on what NFT’s the Treasury fund purchases, as well as when the treasury sells the NFT’s to make profit.

This makes ShibVinci a true DAO backed Treasury where investments from the Treasury are decided 100% by the community.
</p>
              </div>
            </div>
          </div>
           <div class="pt-6">
            <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                    <ScaleIcon  class="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                </div>
                <p class="mt-5 text-base text-gray-500">50% of the profits from the Treasury NFT investments are used to buy back the ShibVinci token from the circulating supply which means there will be less tokens available, therefore increasing the value of the tokens you hold.

The other 50% of Treasury profits will remain in the Treasury for future NFT purchases.
</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/vue/outline'

export default {
  components: { PrimaryButton, GlobeAltIcon, LightningBoltIcon, ScaleIcon },
  name:"Backstory",
};
</script>

<style lang="scss" scoped></style>
