require('dotenv').config();
import { createApp } from 'vue'
import App from './App.vue'
import './styles/app.css';
import routes from "./routes";
import {createRouter, createWebHistory} from "vue-router";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import SecondaryButton from "@/components/buttons/SecondaryButton";
import DefaultButton from "@/components/buttons/DefaultButton";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moralis from 'moralis';
import store from './store';
require('./icons.js');
import 'animate.css';
import Vue3Transitions from 'vue3-transitions'
import config from './config'
import Vue3Lottie from 'vue3-lottie'
import { createPinia } from 'pinia'

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const app = createApp(App);
app.use(createPinia())
app.use(router);
app.use(store);
app.use(Vue3Transitions)
app.use(Vue3Lottie);

app.config.globalProperties.$moralis = moralis;
app.config.globalProperties.$config = config;

// Regisrer Global Components
app.component("PrimaryButton", PrimaryButton);
app.component("SecondaryButton", SecondaryButton);
app.component("DefaultButton", DefaultButton);
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app');