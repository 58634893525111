<template>
  <div class="mt-8 bg-white rounded-xl shadow-sm px-8 pt-8 pb-12 mb-12"  >
     <h1
            class="font-bold text-3xl mb-8 text-secondary "
          >
            <span class="relative block">
              <span class="relative">
                <img
                  class="absolute inset-0 transform translate-y-10"
                  src="/images/underline-simple-orange.svg"
                />
                <span class="relative">Latest Daos</span>
              </span>
            </span>
          </h1>
    <div class="mt-1 space-y-1" aria-labelledby="projects-headline">
      <a
        v-for="item in daos"
        :key="item.name"
        :href="item.link"
        class="group flex items-center  px-3 py-2 text-xl font-medium  text-dark rounded-md  cursor-pointer hover:text-primary"
      >
      {{ item.name }}
       <div class="ml-4">
        <span v-if="item.status==='closed'" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"> CLOSED </span>
         <span v-else class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"> OPEN </span>
       </div>
      </a>
    </div>
  </div>
</template>

<script>
import Moralis from "moralis";
import reduce from 'lodash/reduce';

export default {
  name: "Daos",
  data() {
    return {
      daos: [],
    };
  },
  async mounted() {
    const obj = Moralis.Object.extend("ShibVinciDaos");
    const query = new Moralis.Query(obj);
    query.limit(20)
    query.descending("createdAt");
    const q = await query.find();
    this.daos = reduce(q, (result, item, index) => {
        result.push({
            link: item.get('link'),
            name: item.get('name'),
            status: item.get('status')
        });
        return result;
    }, [])
  },
};
</script>

<style scoped></style>
