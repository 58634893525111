import {
    defineStore
} from 'pinia'

export default defineStore('modals', { 
    state: () => ({
        showStakeModal: false,
        showUnstakeModal: false,
        showWithdrawDividendModal: false,
        showReinvestDividendModal: false
    }),
    actions:{
        toggleWithdrawDividendModal(){
            this.showWithdrawDividendModal = ! this.showWithdrawDividendModal;
        },
        toggleReinvestDividendModal(){
            this.showReinvestDividendModal = ! this.showReinvestDividendModal;
        },
        toggleStakeModal(){
            this.showStakeModal = ! this.showStakeModal;
        },
        toggleUnstakeModal(){
            this.showUnstakeModal = ! this.showUnstakeModal;
        },
    }
});