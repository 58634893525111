<template>
     <div
          class="space-y-6 sm:space-y-5"
     
        >
          <div
            class="sm:grid sm:grid-cols-5 sm:gap-5 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
          >
            <div class="pr-8 col-span-2">
              <p>{{ created }}</p>
               <p class="truncate">{{ item.get("address") }}</p>
            </div>
            <div class="col-span-3">
              <p>{{ item.get("description") }} -> {{ item.get("amount") }} ETH</p>
             <p><a class="text-blue-500 cursor-pointer" target="_blank" :href="'https://etherscan.io/tx/'+item.get('ref')">View On Etherscan</a></p>
            </div>
          </div>
        </div>
</template>

<script>
import moment from 'moment-timezone';

    export default {
        name:"SendMarketingItem",
        props:{
            item:{}
        },
        computed:{
            created(){
                const _moment = new moment(this.item.get("createdAt"));
                return _moment.format('MMM Do, h:mm:ss a');
            }
        }
    }
</script>

<style scoped>

</style>