<template>
<section id="roadmap" class='px-4 pt-12 pb-44 overflow-hidden sm:pb-28 sm:pt-20 sm:px-6 lg:px-8 '>

  <!-- Container -->
  <div class='max-w-xl mx-auto md:max-w-screen-xl'>

    <!-- Section header title and subtext  -->
    <h1 class="font-bold text-4xl lg:text-7xl  mb-8 text-white text-center">
          <span class="relative block">
            <span class="relative">
              <img
                class="absolute inset-0 transform translate-y-9 sm:translate-y-14"
                src="images/underline-simple-orange.svg"
              />
              <span class="relative">Roadmap</span>
            </span>
          </span>
        </h1>
    
    <!-- School programs -->
    <div class='pt-8 md:grid md:grid-cols-2 md:gap-8 lg:gap-16 relative'>

      <!-- Program 1 and background dotted line decorations -->
      <img src='images/curved-dotted-line.svg' class='line-1-2 absolute hidden md:block' >
       <img src='images/looped-dotted-line.svg' class='line-2-3 absolute hidden md:block'>
      <img src='images/curved-dotted-line.svg' class='line-3-4 absolute hidden md:block'> 

      <!-- Program 1 -->
      <div class='relative w-full md:-translate-y-80 '>
        <div class='flex flex-col justify-between  rounded-3xl bg-primary-lighter  px-8 py-10   sm:p-12 md:mt-0 md:px-8 md:py-10 lg:p-12'>
          <div class='flex-1'>
                     <h3 class='font-written text-3xl text-primary'>
              Q1 2022 - ShibVinci Launch Phase
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
                <ul class="mt-5 space-y-5 text-lg text-purple-800">
                  <roadmap-list-item :complete="true">Launch social media accounts</roadmap-list-item>
                  <roadmap-list-item :complete="true">Develop website & ShibVinci smart contract</roadmap-list-item>
                  <roadmap-list-item :complete="true">Initial marketing campaign with influencers, advertisements and competitions</roadmap-list-item>
                  <roadmap-list-item :complete="true">Listings on small - mid tier coin trackers (Coinhunt, Coinsniper etc.)</roadmap-list-item>
                  <roadmap-list-item :complete="true">Push marketing campaign with big influencers and shillers</roadmap-list-item>
                  <roadmap-list-item :complete="true">First community DAO investment vote</roadmap-list-item>
                  <roadmap-list-item :complete="true">First “blue chip” nft purchase of what will become one of the worlds largest NFT collections</roadmap-list-item>
                  <roadmap-list-item :complete="true">Launch V1 of the Shibvinci D-app</roadmap-list-item>
                </ul>
            </p>
          </div>
        </div>
      </div>

      <!-- Program 2 -->
      <div class='relative w-full  mt-12 md:mt-52'>
        <div class='flex flex-col justify-between rounded-3xl bg-secondary-lighter px-8 py-10  sm:p-12  md:px-8 md:py-10 lg:p-12'>
          <div class='flex-1'>  
              <h3 class='font-written text-3xl text-secondary'>
              Q2 2022 - Project Foundations
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose '>
                <ul class="mt-5 space-y-5 text-lg ">
                  <roadmap-list-item :complete="true">Listing on CoinGecko</roadmap-list-item>
                  <roadmap-list-item :complete="true">Reach the first portfolio target of $100k value</roadmap-list-item>
                  <roadmap-list-item :complete="true">Create a virtual gallery to display our NFTs</roadmap-list-item>
                  <roadmap-list-item :complete="true">Begin creating partnerships with NFT projects</roadmap-list-item>
                  <roadmap-list-item :complete="true" >Begin creating partnerships with other token projects </roadmap-list-item>
                  <roadmap-list-item :complete="true" >Burn wallet in the top 10 holders </roadmap-list-item>
                  <roadmap-list-item :complete="false" >Listing on Coin market cap</roadmap-list-item>
                  <roadmap-list-item :complete="true" >Make our first NFT sale for profit and burn $SHIV</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Profit share function added to the D-app</roadmap-list-item>
                  <roadmap-list-item :complete="true" >The DAO decides on the first Metaverse plot</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Community NFT’s displayed in our Metaverse Gallery</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Implement traditional & scalable marketing - Youtube ads, Facebook & Instagram ads</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Release the first ShibVinci music NFT</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Website & White paper redesign</roadmap-list-item>
                  <roadmap-list-item :complete="false" >Partner with a crypto PR company</roadmap-list-item>
                </ul>
            </p>
          </div>
        </div>
      </div>

      <!-- Program 3 -->
      <div class='relative w-full  md:-translate-y-80  '>
        <div class='flex flex-col justify-between rounded-3xl bg-primary-lighter px-8 py-10 mt-12 sm:p-12 md:mt-0 md:px-8 md:py-10 lg:p-12'>
          <div class='flex-1'>
                     <h3 class='font-written text-3xl text-primary'>
              H2 2022 - Project Development & Targets
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose '>
                <ul class="mt-5 space-y-5 text-lg ">
                  <roadmap-list-item >Release the Vinci Swap NFT trading Dapp</roadmap-list-item>
                  <roadmap-list-item >Establish ShibVinci as a launchpad for new NFT projects and artists</roadmap-list-item>
                  <roadmap-list-item >CEX listings  </roadmap-list-item>
                  <roadmap-list-item >Burn wallet as the number 1 holder of $SHIV</roadmap-list-item>
                  <roadmap-list-item >$500k - $1,000,000 NFT portfolio </roadmap-list-item>
                  <roadmap-list-item >$100,000 of $SHIV burnt </roadmap-list-item>
                  <roadmap-list-item >$100,000 profit share for holders</roadmap-list-item>
                </ul>
            </p>
          </div>
        </div>
      </div>
      
        <!-- Program 4 -->
      <div class='relative w-full md:translate-y-56 md:mt-80 '>
        <div class='flex flex-col justify-between rounded-3xl bg-secondary-lighter  px-8 py-10 mt-12 sm:p-12 md:mt-0  md:px-8 md:py-10 lg:p-12'>
          <div class='flex-1'>
                     <h3 class='font-written text-3xl text-secondary'>
              H1 2023 - Project Development 
            </h3>
            <p class='max-w-2xl mt-3 text-lg leading-loose text-purple-800'>
                <ul class="mt-5 space-y-5 text-lg text-purple-800">
                  <roadmap-list-item >Coming soon </roadmap-list-item>
                </ul>
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
  
</section>
</template>

<script>
import RoadmapLines from './RoadmapLines.vue'
import RoadmapListItem from './RoadmapListItem.vue';




export default {
  name: "Roadmap",
  components:{
    RoadmapLines,
    RoadmapListItem
  },
  data:()=>({
    stage: "preparing for launch"
  })
};
</script>

<style scoped>
.blink {
  animation: blinker 2s infinite;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.line-1-2 {
  top: 60px;
  right: 285px;
  width: 350px;
  transform: rotate(80deg);
}
.line-2-3 {
  top: 801px;
  left: 282px;
  width: 385px;
  transform: rotate(90deg);
}
.line-3-4 {
  top: 1275px;
  right: 330px;
  width: 350px;
  transform: rotate(75deg);
}
</style>
