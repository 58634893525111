import {
    defineStore
} from 'pinia'
import Moralis from 'moralis';
import reduce from 'lodash/reduce'
import find from 'lodash/find';
import moment from 'moment-timezone'
import useEthUsdPrice from './useEthUsdPrice';
import axios from 'axios';

export default defineStore('nfts', {
    state: () => ({
        fetchingData: false,
        gotData: false,
        error: false,
        totalNfts: 0,
        nfts: [],
        projectFloorValues: {}
    }),
    getters: {
        estFloorValue(state) {
            if (!state.gotData) return 0;
            const value =  reduce(this.nfts, (result, item, index) => {
                let floor = state.projectFloorValues[item.token_address];
                floor = floor ? floor : 0;
                return result + parseFloat(floor);
            }, 0.00);
            return value.toFixed(2);
        },
        // Returns the unqiue contracts address from
        // the nfts array
        uniqueProjects(state) {
            if (!state.nfts) return [];
            return reduce(state.nfts, (result, item, index) => {
                if (!result.includes(item.token_address)) {
                    result.push(item.token_address)
                }
                return result;
            }, []);
        },
        estFloorValueUsd(state){            
            if (!state.gotData) 
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    currencyDisplay: 'narrowSymbol'
                }).format("0");

            const math = require('mathjs')
            const ethUsdPrice = useEthUsdPrice();       
            const value = math.multiply(this.estFloorValue, ethUsdPrice.value);
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              currencyDisplay: 'narrowSymbol'
            }).format(value)
        },
        getFloorForContract: (state) => {
            return (token_address) => state.projectFloorValues[token_address]
        }
    },
    actions: {
        async fetchData() {
            this.fetchingData = true;

            try {
                const options = {
                    address: "0x58906aCB962b3FE294A282BeC15ba079BDa43aE6"
                };
                
                const req = await Moralis.Web3API.account.getNFTs(options);
                this.totalNfts = req.total;
                this.nfts = req.result;

                await this.getProjectFloorValues();

                this.fetchingData = false;
                this.gotData = true;
            } catch (err) {
                console.error(err);
                this.gotData = true;
                this.fetchingData = false;
                this.error = err.message;
            }
        },
        async saveFloor({
            ca,
            floor
        }) {
            const NftFloors = Moralis.Object.extend("NftFloors");
            const query = new Moralis.Query(NftFloors);
            query.equalTo('token_address', ca);
            const result = await query.first();

            if(result){
                result.set('floor_price', floor+"");
                await result.save();
            }else {
                const newFloor = new NftFloors();
                newFloor.set('token_address', ca);
                newFloor.set('floor_price', floor+"");
                await newFloor.save();
            }
        },
        async getProjectFloorValues() {
            const cachedFloors = await this.getCachedFloors();

            // Get the floor value for each project
            for (let i = 0; i < this.uniqueProjects.length; i++) {
                const ca = this.uniqueProjects[i];
                if (cachedFloors[ca]) {
                    this.projectFloorValues[ca] = cachedFloors[ca];
                } else {
                    const options = {
                        address: ca,
                        days: "3"
                    };
                    
                    try {
                        const req = await axios.get("https://api.opensea.io/api/v1/asset_contract/"+ca);
                        const slug = req.data.collection.slug;
                        const req2 = await axios.get("https://api.opensea.io/api/v1/collection/" + slug);
                        //console.log(req2);
                        // const floor = parseFloat(Moralis.Units.FromWei(NFTLowestPrice.price)).toFixed(2);
                        const floor = (req2.data.collection.stats.floor_price) ? req2.data.collection.stats.floor_price : req2.data.collection.stats.average_price;
                        this.projectFloorValues[ca] = floor;
                        
                        // Don't async this as its not required for the UI we can just do in background
                        this.saveFloor({
                            ca,
                            floor
                        })   
                    } catch(e) {}
                }
            }
            this.nfts = this.nfts.filter(nft => {
                if(this.projectFloorValues[nft.token_address] != undefined)
                    return nft;
            });
        },
        async getCachedFloors() {
            // Get the latest floors from our db
            const NftFloors = Moralis.Object.extend("NftFloors");
            const query = new Moralis.Query(NftFloors);
            query.containedIn("token_address", this.uniqueProjects);
            const dateObj = moment().subtract('3', 'days').utc(true);
            const dateFrom = new Date(dateObj.format('YYYY'), dateObj.format('MM') - 1, dateObj.format('DD'));
            query.greaterThan('updatedAt', dateFrom);
            const results = await query.find();

            return reduce(results, (result, item, index) => {
                result[item.get('token_address')] = item.get('floor_price');
                return result;
            }, {});
        }
    },

});