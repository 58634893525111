<template>
<div class='px-4 sm:px-6'>
  <no-dapp-modal :open="open" @close="closeModal"/>
  <nav class='flex items-center max-w-screen-xl pt-5 mx-auto'>
    <div class='flex items-center justify-between w-full'>

      <!-- Main navigation menu for large screens -->
      <div class='items-center justify-between hidden lg:flex md:space-x-6 lg:space-x-10'>
          <navigation-item link="about" text="About" />
          <navigation-item link="treasury" text="Treasury" />
          <navigation-item link="tokenomics" text="Tokenomics" />
          <navigation-item link="howtobuy" text="How to Buy" />
          <navigation-item link="roadmap" text="Roadmap" />
      </div>

      <!-- Call to action button -->
      <div class='hidden lg:block'>
    <secondary-button xl @click="openModal">
            <font-awesome-icon icon="rocket-launch" class="mr-2" />
            Launch Dapp
        </secondary-button>
      </div>

    </div>
  </nav>
</div>
</template>
<script>
import NoDappModal from '../NoDappModal.vue'
import NavigationItem from './NavigationItem.vue'
export default {
    name: "Navigation",
    components: { NavigationItem, NoDappModal },
    data(){
      return {
        open: false
      }
    },
    methods:{
      openModal(){
           this.$router.push({path:'/dapp'})
      },
      closeModal(){
        this.open = false;
      }
    }
}
</script>