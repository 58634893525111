<template>
  <website-layout>
    <main>
      <hero />
      <intro />
      <buy-now-alt />      
      <treasury />
       <tokenomics />
      <how-to-buy /> 
       <roadmap />
       <buy-now /> 
    </main>
  </website-layout>
</template>

<script>
import Hero from "../modules/website/components/Hero.vue";
import WebsiteLayout from "../layouts/WebsiteLayout.vue";
import Intro from "../modules/website/components/Intro.vue";
import Backstory from "../modules/website/components/Backstory.vue";
import HowToBuy from "../modules/website/components/HowToBuy.vue";
import Tokenomics from "../modules/website/components/Tokenomics.vue";
import Roadmap from "../modules/website/components/Roadmap.vue";
import BuyNow from "../modules/website/components/BuyNow.vue";
import BuyNowAlt from "../modules/website/components/BuyNowAlt.vue";
import Treasury from '../modules/website/components/Treasury.vue';

export default {
  name: "Index",
  components: {
    HowToBuy,
    WebsiteLayout,
    Hero,
    Intro,
    Backstory,
    Tokenomics,
    Roadmap,
    BuyNow,
    BuyNowAlt,
    Treasury
  },
};
</script>
