<template>
   <div class="relative pb-8">
          <div class="relative flex space-x-3">
            <div>
              <span :class="['h-8 w-8 rounded-full flex items-center justify-center bg-green-400 ring-8 ring-green-400 text-white']">
                <font-awesome-icon icon="dollar-sign" />
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div>
                <p class="text-sm text-gray-500">
                  <a :href="href" target="_blank" class="font-medium text-gray-900 ml-2">{{ tokenAmount }}</a> $SHIV Was bought back!              </p>
              </div>
              <div class="text-right text-sm whitespace-nowrap text-gray-500">
                <time :datetime="transaction.createdAt">{{ transaction.createdAt }}</time>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Moralis from 'moralis';
    
    export default {
        name:"burn",
        props:{
            transaction:{}
        },
        computed:{
            tokenAmount(){
                return  Moralis.Units.FromWei(this.transaction.value, 18);
            },
            href(){
                return "https://etherscan.io/tx/" + this.transaction.transaction_hash
            }
        }
    }
</script>

<style scoped>

</style>