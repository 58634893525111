import Dapp from "./pages/Dapp";
import Treasury from "./modules/dapp/treasury/treasury.vue";
import Gallery from "./modules/dapp/gallery/gallery.vue";
import Buybacks from "./modules/dapp/buybacks/buybacks.vue";
import Dashboard from "./modules/dapp/dashboard/Dashboard.vue";
import Dividends from "./modules/dapp/dividends/dividends.vue";
import ComingSoon from "./modules/dapp/dividends/ComingSoon.vue";
import Website from "./pages/Index.vue";
import SendMarketing from './pages/SendMarketing.vue'

const routes = [
  { path: '/', component: Website },
  { 
    path: '/dapp', 
    component: Dapp,
    children:[
      {
        path: "buybacks",
        name:"Buybacks",
        component: Buybacks,
      },
      {
        path: "gallery",
        name:"Gallery",
        component: Gallery,
      },
      {
        path: "rewards",
        name:"Dividends",
        component: ComingSoon,
      },
      {
        path: "treasury",
        name:"Treasury",
        component: Treasury,
      },
      {
        path: "",
        name:"dashboard",
        component: Dashboard,
      },
    ] 
  },
  { path: '/sendlambo', component: SendMarketing },

]

export default routes;