<script setup>
import { defineProps } from "vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import { parseNftImageUrl } from "@/utils.js";
import useNfts from '@stores/useNfts'

const props = defineProps({
  nft: {},
});

const nfts = useNfts();

const metadata = ref(
  props.nft.metadata ? JSON.parse(props.nft.metadata) : false
);
const image = ref("");
const floor = ref(0);
const link = ref("")
const isVideo = ref(false);

onMounted(async () => {
  if (metadata.value) {
    if(metadata.value.image == undefined) {
      if(metadata.value.animation_url)
        image.value = metadata.value.animation_url.replace("ipfs://", "https://gateway.ipfs.io/ipfs/");
      isVideo.value = true;
    }
    else 
      image.value = parseNftImageUrl(metadata.value.image);
  } else {
    // let url = props.nft.token_uri.replace("https://guttercats.in/", "http://localhost:8080/");
    const req = await axios.get(props.nft.token_uri,  {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      }
    });
    image.value = parseNftImageUrl(req.data.image);
  }

  floor.value = nfts.projectFloorValues[props.nft.token_address]
  link.value = "https://opensea.io/assets/"+props.nft.token_address+"/"+props.nft.token_id;
});
</script>

<template>
  <div class="gallery-item relative shadow-xl rounded-2xl mb-8">
    <div class="background flex justify-center items-center ">
          <img v-if="image && !isVideo" :src="image" class="img-fluid image" />
          <video v-if="image && isVideo" :src="image" muted autoplay loop class="img-fluid image" />
      </div>

    <div class="mt-4 text-center pb-6 ">
      <h1 class="text-xl font-extrabold text-primary">{{ metadata?.name }}</h1>
      <p class="text-md font-bold text-gray-50 mb-4">Floor: {{ floor }} ETH</p>
      <a :href="link" class="text-white cursor-pointer pt-8" target="_blank">View On OpenSea</a>
    </div>
  </div>
</template>

<style scoped>
.gallery-item {
  margin-left: 25px;
  margin-right: 25px;
  overflow: hidden;
  background: linear-gradient(
    to top,
    rgba(0, 38, 73, 0.9),
    rgba(0, 38, 73, 0.5)
  );
}
.frame img {
  width: 350px;
  height: 314px;
}
.image {
  /* max-width: 235px;
  margin: 0px auto; */
}
.background {
  background: black;
  height: 250px;
}
.background img {
  max-width: 100%;
  max-height:100%;
}
.image-holder {
  width: 350px;
  height: 314px;
}
</style>
