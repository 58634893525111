<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="close">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg  text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:w-full "
            style="height: 494px; width: 640px"
          >
            <img
              src="/img/gallery.jpg"
              class="absolute top-0 bottom-0 left-0 right-0 z-10"
            />
            <div class="relative z-20 h-full">
              <div class="absolute top-12 left-0 right-0">
                <p class="text-2xl font-written text-dark text-center">
                  Our Dapp and NFT Gallery will be opening soon
                </p>
              </div>
              <div class="absolute bottom-4 left-0 flex justify-center right-0">
                <secondary-button
                  type="button"
                  @click="$emit('close')"
                  lg
                >
                  Back to Website
                </secondary-button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";
import SecondaryButton from '../../../components/buttons/SecondaryButton.vue';

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
     SecondaryButton
  },
  props: {
    open: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
