<template>
  <primary-button xl class="mr-0 lg:mr-8 " alt @click="goToTelegram">
    <font-awesome-icon :icon="['fab', 'telegram']" class="mr-4" /> Join The
    Telegram
  </primary-button>
</template>

<script>
import SecondaryButton from "./SecondaryButton.vue";
import {telegram} from '@/links'
export default {
  name: "TelegramButton",
  components: {
    SecondaryButton,
  },
  methods: {
    goToTelegram() {
      window.open(telegram, "_blank").focus();
    },
  },
};
</script>

<style scoped></style>
