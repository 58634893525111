<template>
    <div aria-label="Sidebar  " class="w-full">
      <div class="md:flex justify-end w-full mt-12 md:mt-0">
        <router-link
          v-for="item in navigation"
          :key="item.name"
          :to="item.href"
          :class="[
            item.current ? ' text-primary' : 'text-gray-100 hover:text-primary',
            'group flex items-center px-3 mx-4 py-2 cursor-pointer text-lg tracking-wider  rounded-md ',
          ]"
          :aria-current="item.current ? 'page' : undefined"
        >
          <font-awesome-icon
            :icon="item.icon"
            :class="[
              item.current
                ? 'text-primary'
                : 'text-gray-100 group-hover:text-primary',
              'flex-shrink-0 -ml-1 mr-3 h-6 w-6 ',
            ]"
          />
          <span class="truncate">
            {{ item.name }}
          </span>
        </router-link>
        <wallet-connect>
          <current-balance />
        </wallet-connect>
      </div>
    </div>
</template>

<script>
import {
  PhotographIcon,
  CollectionIcon,
  FolderIcon,
  HomeIcon,
  ShareIcon,
  UsersIcon,
} from "@heroicons/vue/outline";
import WalletConnect from '../../../components/WalletConnect.vue';
import CurrentBalance from '../../../components/CurrentBalance.vue';

export default {
  name: "Sidebar",
  components: {
    WalletConnect,
    CurrentBalance,
  },
  computed: {
      navigation() {
      return [
        { name: "Dashboard", href: "/dapp", icon: "home",current: (this.$route.name==='dashboard'),},
        { name: "Treasury", href: "/dapp/treasury", icon: "coins", current: (this.$route.name==='Treasury') },
        {
          name: "Gallery",
          href: "/dapp/gallery",
          icon: "images",
          current: this.$route.name === "Gallery",
        },
        {
          name: "Rewards",
          href: "/dapp/rewards",
          icon: "treasure-chest",
          current: this.$route.name === "Dividends",
        },
        {
          name: "Website",
          href: "/",
          icon: "globe",
          current: this.$route.name === "Website",
        },
        // {
        //   name: "Buybacks",
        //   href: "/dapp/buybacks",
        //   icon: "repeat",
        //   current: this.$route.name === "Buybacks",
        // },
      ];
    },
  },
};
</script>

<style scoped>

</style>
